import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Theme,
  TextField,
  InputAdornment,
  SxProps,
} from '@mui/material';
// ICONS
import SearchIcon from '@mui/icons-material/Search';
// CONSTANTS
import Countries, { ICountry } from '../../../constants/Countries';

export interface TimezoneModalProps {
  open: boolean;
  onClose: () => void;
  selectedTimezone: ICountry;
  onTimezoneChange?: (country: ICountry) => void;
}

// STYLES
const listContainerStyles: SxProps<Theme> = {
  maxHeight: '50vh',
  overflowY: 'auto',
  m: '0 -24px',
};

const TimezoneModal: React.FC<TimezoneModalProps> = ({
  open,
  onClose,
  selectedTimezone,
  onTimezoneChange,
}) => {
  // LOCAL STATES
  const [searchText, setSearchText] = useState('');

  // METHODS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchText(value);
  };

  const _countries = Countries.filter((country) =>
    country.value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
  );

  // RENDER
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ style: { minWidth: '400px' } }}
    >
      <DialogTitle>Select Timezone</DialogTitle>
      <DialogContent>
        <div>
          <div style={{ margin: '15px 0' }}>
            <TextField
              fullWidth
              size="small"
              value={searchText}
              placeholder="Asia / Singapore"
              className="mui-text-field-label-hidden"
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <List sx={listContainerStyles}>
            {_countries.map((country, index) => (
              <ListItem
                button
                key={index}
                selected={country.value === selectedTimezone.value}
                onClick={
                  onTimezoneChange ? () => onTimezoneChange(country) : undefined
                }
              >
                <ListItemText primary={country.value} />
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TimezoneModal;
