import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';
import { IFindUsOption } from '.';

const FindUsApiDataProvider = new APIDataProvider<IFindUsOption>();

export const getFindUsOptions = createAsyncThunk('findUs/list', async () => {
  const response = await FindUsApiDataProvider.get(ApiPaths.findUs);

  return response.data;
});

export default getFindUsOptions;
