import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import { getCurriculums } from './CurriculumApi';

export interface IGrade {
  grade: string;
}

export interface ISubject {
  subject: string;
  grade: IGrade[];
}

export interface ICurriculum {
  id: string;
  curriculum: string;
  subjects: ISubject[];
}

export interface ICurriculumState {
  status: 'idle' | 'loading' | 'rejected';
  list: Array<ICurriculum>;
}

const initialState: ICurriculumState = {
  status: 'idle',
  list: [],
};

export const CurriculumSlice = createSlice({
  name: 'curriculum',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getCurriculums.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCurriculums.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getCurriculums.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getCurriculumState = (state: RootState): RootState['curriculum'] =>
  state.curriculum;

export * from './CurriculumApi';
export default CurriculumSlice.reducer;
