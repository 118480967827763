import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser, ISignupUser } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';

const SignupApiDataProvider = new APIDataProvider<IUser>();

export const signupUser = createAsyncThunk(
  'signup/create',
  async (data: ISignupUser) => {
    const response = await SignupApiDataProvider.create(ApiPaths.signup, data);

    return response.data;
  }
);

export default signupUser;
