import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';
import { IFreeSlotList } from '.';

export interface IGetFreeSlotSelect {
  subject: string;
  curriculum: string;
  grade: string;
  date: string;
  studentId: string;
  studentCountryOverRide?: string;
  type: string;
}

const FreeSlotDataProvider = new APIDataProvider<IFreeSlotList>();

export const getSelectedFreeSlots = createAsyncThunk(
  'freeSlotSelect/list',
  async (data: IGetFreeSlotSelect) => {
    const response = await FreeSlotDataProvider.create(
      ApiPaths.freeSlotSelect,
      data
    );

    return response.data;
  }
);

export default getSelectedFreeSlots;
