import { createAsyncThunk } from '@reduxjs/toolkit';
import { IFreeTutor } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';

export interface IGetFreeTutors {
  trialId: string;
}

const FreeTutorsApiDataProvider = new APIDataProvider<IFreeTutor[]>();

export const getFreeTutors = createAsyncThunk(
  'freeTutor/list',
  async (data: IGetFreeTutors) => {
    const response = await FreeTutorsApiDataProvider.create(
      ApiPaths.freeTutor,
      data
    );

    return response.data;
  }
);

export default getFreeTutors;
