import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import {
  getOnboardingSubjectSettingsData,
  validateUrlParams,
} from './SettingsApi';

export interface IOnboardingSubjectSettingsData {
  isAllowed: boolean;
}
export interface IValidatedUrlParamData {
  curriculum?: string;
  grade?: string;
  subject?: string;
}
export interface ICheckSettingsState {
  status: 'idle' | 'loading' | 'rejected';
  onboardingSubjectSettingsData: IOnboardingSubjectSettingsData | undefined;
  validatedUrlParamData: IValidatedUrlParamData | undefined;
}

const initialState: ICheckSettingsState = {
  status: 'idle',
  onboardingSubjectSettingsData: undefined,
  validatedUrlParamData: undefined,
};

export const SettingsSlice = createSlice({
  name: 'checkSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnboardingSubjectSettingsData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOnboardingSubjectSettingsData.fulfilled, (state, action) => {
        state.status = 'idle';
        state.onboardingSubjectSettingsData = action.payload;
      })
      .addCase(getOnboardingSubjectSettingsData.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(validateUrlParams.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateUrlParams.fulfilled, (state, action) => {
        state.status = 'idle';
        state.validatedUrlParamData = action.payload;
      })
      .addCase(validateUrlParams.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getSettingsState = (state: RootState): RootState['settings'] =>
  state.settings;

export * from './SettingsApi';
export default SettingsSlice.reducer;
