import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '..';

export interface IAnalytics {
  userId?: string;
  type: string;
  data: Record<string, unknown>;
}

export interface IAnalyticsState {
  status: 'idle' | 'loading' | 'rejected';
}

const initialState: IAnalyticsState = {
  status: 'idle',
};

export const AnalyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
});

export const getAnalyticsState = (state: RootState): RootState['analytics'] =>
  state.analytics;

export * from './AnalyticsApi';
export default AnalyticsSlice.reducer;
