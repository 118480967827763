import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, BoxProps, Typography, Grid } from '@mui/material';

import {
  getCurriculums,
  getCurriculumState,
  ICurriculum,
} from '../../../store/CurriculumSlice';
import {
  getTrialUserById,
  getTrialUserState,
  setHadCurriculum,
  updateCurriculum,
} from '../../../store/TrialUserSlice';
import { getSignupState, signupUser } from '../../../store/SignupSlice';
import { useAppSelector } from '../../../hooks';
import { removeTutopiyaPlatformTokenFromLocalStorage } from '../../../helper';
import SubjectLevelCard from '../components/SubjectLevelCard';
import BasicLoader from '../../../components/Loaders/BasicLoader';
import CurriculumsModal from '../modals/CurriculumsModal';

interface IState {
  selectedCurriculum: ICurriculum | undefined;
  isCurriculumsModalOpen: boolean;
}

interface Step2Props {
  onTabChange: () => void;
  pageType: string;
}

const containerStyles: React.CSSProperties = {
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const buttonStyles: BoxProps['sx'] = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0',
  backgroundColor: 'background.default',
  position: 'absolute',
  bottom: '0',
  right: '0',
  width: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '40%',
  },
};

const Step2: React.FC<Step2Props> = ({ onTabChange, pageType }) => {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', 'curriculum');
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);

  // Store
  const curriculumState = useAppSelector(getCurriculumState);
  const signupState = useAppSelector(getSignupState);
  const trialUserState = useAppSelector(getTrialUserState);

  // State
  const [isRedirectReady, setRedirectReady] = useState(false);
  const [state, setState] = useState<IState>({
    selectedCurriculum: undefined,
    isCurriculumsModalOpen: false,
  });

  const signUpUser = signupState.user;
  const trialUser = trialUserState.user;
  const isCurriculumLoading = curriculumState.status === 'loading';
  const isSignupLoading = signupState.status === 'loading';
  const prioritizeList = curriculumState.list.slice(0, 11);

  useEffect(() => {
    if (pageType === 'trial') {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userId');

      if (userId) {
        // Remove tutopiya platform token from the local storage if exists
        removeTutopiyaPlatformTokenFromLocalStorage();

        dispatch(getTrialUserById(userId));
      }
    }

    dispatch(getCurriculums());
  }, []);

  useEffect(() => {
    if (pageType === 'trial' && trialUser && trialUser.curriculum) {
      onTabChange();
    }
  }, [trialUser]);

  useEffect(() => {
    if (signUpUser && signUpUser.curriculum && isRedirectReady) {
      onTabChange();
    }
  }, [signUpUser]);

  useEffect(() => {
    if (
      curriculumState.list &&
      curriculumState.list.length &&
      signUpUser &&
      signUpUser.curriculum
    ) {
      setState((prevState) => {
        const curriculumItem = curriculumState.list.find(
          (item) => item.curriculum === signUpUser?.curriculum
        );

        return {
          ...prevState,
          selectedCurriculum: curriculumItem || prevState.selectedCurriculum,
        };
      });
    }
  }, [curriculumState.list]);

  const onSubmit = () => {
    if (state.selectedCurriculum && state.selectedCurriculum.curriculum) {
      if (pageType === 'onboarding' && signUpUser && signUpUser.id) {
        dispatch(
          signupUser({
            id: signUpUser.id,
            curriculum: state.selectedCurriculum.curriculum,
          })
        );

        setRedirectReady(true);
      } else if (pageType === 'trial' && trialUser && trialUser.id) {
        dispatch(updateCurriculum(state.selectedCurriculum.curriculum));
        dispatch(setHadCurriculum(false));
      }
    }
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <Typography variant="h5" fontWeight={600}>
            Select Level
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color="secondary">
            Please Select the level you&apos;re in right now
          </Typography>
        </div>

        <div style={{ padding: '0 30px' }}>
          {isCurriculumLoading ? (
            <BasicLoader />
          ) : (
            <Grid container spacing={2}>
              {prioritizeList.map((curriculum, index) => {
                const _isSelected =
                  state.selectedCurriculum?.id === curriculum.id;

                return (
                  <Grid key={index} item xs={4} sm={3} md={2} lg={3}>
                    <SubjectLevelCard
                      selected={_isSelected}
                      curriculum={curriculum.curriculum}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          selectedCurriculum: curriculum,
                        }))
                      }
                    />
                  </Grid>
                );
              })}

              {curriculumState.list.length > 11 && (
                <Grid item xs={4} sm={3} md={2} lg={3}>
                  <SubjectLevelCard
                    disabled={curriculumState.list.length <= 11}
                    selected={
                      Boolean(state.selectedCurriculum) &&
                      !prioritizeList.some(
                        (c) => c === state.selectedCurriculum
                      )
                    }
                    curriculum={`${
                      state.selectedCurriculum &&
                      curriculumState.list.indexOf(state.selectedCurriculum) >=
                        11
                        ? `${state.selectedCurriculum.curriculum} (Other)`
                        : 'Other'
                    }`}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        isCurriculumsModalOpen: true,
                      }))
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </div>
      <div style={{ minHeight: 'calc(66.5px + 15px)' }} />
      <div>
        <Box sx={buttonStyles}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            sx={{ maxWidth: '300px' }}
            disabled={!state.selectedCurriculum}
            onClick={onSubmit}
            loading={isSignupLoading}
            className="onboarding-level-next-button"
          >
            Next
          </LoadingButton>
        </Box>
      </div>
      {/* MODALS */}
      <CurriculumsModal
        open={state.isCurriculumsModalOpen}
        selectedCurriculum={state.selectedCurriculum}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCurriculumsModalOpen: false,
          }))
        }
        onCurriculumChange={(curriculum) => {
          setState((prevState) => ({
            ...prevState,
            selectedCurriculum: curriculum,
            isCurriculumsModalOpen: false,
          }));
        }}
      />
    </div>
  );
};

export default Step2;
