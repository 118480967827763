import { createAsyncThunk } from '@reduxjs/toolkit';
import http from '../../dataProvider/AuthBaseRequest';

export const getClientCredentialsToken = createAsyncThunk(
  'signup/client_credentials',
  async () => {
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    params.append(
      'client_id',
      process.env.REACT_APP_TUTOPIYA_CLIENT_ID as string
    );
    params.append(
      'client_secret',
      process.env.REACT_APP_TUTOPIYA_CLIENT_SECRET as string
    );

    return http.post('/oauth/token', params).then((res) => res.data);
  }
);

export default getClientCredentialsToken;
