import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';
import { IGrade } from '../CurriculumSlice';

export interface IGetGrades {
  curriculum: string;
  subject: string;
}

const GradeApiDataProvider = new APIDataProvider<IGrade[]>();

export const getGrades = createAsyncThunk(
  'grade/list',
  async (data: IGetGrades) => {
    const response = await GradeApiDataProvider.create(ApiPaths.grade, data);
    return response.data;
  }
);

export default getGrades;
