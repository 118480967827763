import React from 'react';
import { Typography } from '@mui/material';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

const ImageContents: React.FC = () => {
  return (
    <div>
      <img
        src="/images/steps/steps-lg.png"
        alt="steps"
        style={{
          width: '100%',
          maxWidth: '600px',
          minWidth: '400px',
        }}
      />
      <div style={{ textAlign: 'center', marginTop: '30px' }}>
        <Typography variant="h5" color={TP_COLORS.white}>
          Live Online Tuition
        </Typography>
        <Typography
          variant="subtitle2"
          color={TP_COLORS.white}
          sx={{ opacity: '0.5' }}
        >
          Get an expert Tutor in the comfort of your own home with these
          <br />
          simple steps !
        </Typography>
      </div>
    </div>
  );
};

export default ImageContents;
