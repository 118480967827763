/* eslint-disable class-methods-use-this */
import http from './BaseRequest';

export interface IApiResponse<T> {
  code: number;
  message: string;
  data: T;
}

export interface IUpdateById {
  id: string;
}

class APIDataProvider<T> {
  get(path: string): Promise<IApiResponse<Array<T>>> {
    return http()
      .get(path)
      .then((res) => res.data)
      .catch((err) => {
        return Promise.reject(new Error(JSON.stringify(err.response)));
      });
  }

  getById(path: string): Promise<IApiResponse<T>> {
    return http()
      .get(path)
      .then((res) => res.data)
      .catch((err) => {
        return Promise.reject(new Error(JSON.stringify(err.response)));
      });
  }

  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  create(path: string, data: any): Promise<IApiResponse<T>> {
    return http()
      .post(path, data)
      .then((res) => res.data)
      .catch((err) => {
        return Promise.reject(new Error(JSON.stringify(err.response)));
      });
  }

  updateById(path: string, data: IUpdateById): Promise<IApiResponse<T>> {
    return http()
      .patch(path, data)
      .then((res) => res.data)
      .catch((err) => {
        return Promise.reject(new Error(JSON.stringify(err.response)));
      });
  }

  // deleteById(path: string, data: any): Promise<IApiResponse<T>> {
  //   return http
  //     .delete(path, data)
  //     .then((res) => res.data)
  //     .catch((err) => {
  //       return Promise.reject(new Error(JSON.stringify(err.response)));
  //     });
  // }

  // updateById(path: string, data: any): Promise<IApiResponse<T>> {
  //   return http
  //     .patch(path, data)
  //     .then((res) => res.data)
  //     .catch((err) => {
  //       return Promise.reject(new Error(JSON.stringify(err.response)));
  //     });
  // }
}

export default APIDataProvider;
