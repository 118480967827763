/* eslint-disable */
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// SLICES
import AuthReducer from './AuthSlice';
import CurriculumReducer from './CurriculumSlice';
import SubjectReducer from './SubjectSlice';
import FindUsReducer from './FindUsSlice';
import SignupReducer from './SignupSlice';
import GradeReducer from './GradeSlice';
import FreeSlotReducer from './settings';
import TrialReducer from './TrialSlice';
import FreeTutorReducer from './FreeTutorSlice';
import FreeSlotSelectReducer from './FreeSlotSelectSlice';
import TrilaUserReducer from './TrialUserSlice';
import AnalyticsReducer from './AnalyticsSlice';
// OTHERS
import ConfigReducer from './ConfigSlice';
import ErrorReducer from './ErrorSlice';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    curriculum: CurriculumReducer,
    subject: SubjectReducer,
    findUs: FindUsReducer,
    signUp: SignupReducer,
    grade: GradeReducer,
    settings: FreeSlotReducer,
    freeSlotSelect: FreeSlotSelectReducer,
    trial: TrialReducer,
    freeTutor: FreeTutorReducer,
    trialUser: TrilaUserReducer,
    analytics: AnalyticsReducer,
    // OTHER SLICE
    config: ConfigReducer,
    error: ErrorReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
