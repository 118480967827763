import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

const containerStyles: React.CSSProperties = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const InvalidRoute: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div style={containerStyles}>
      <Typography variant="h4">Invalid Location</Typography>
      <Button color="primary" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </div>
  );
};

export default InvalidRoute;
