import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { IUser } from '../SignupSlice';
// API
import { getFreeTutors } from './FreeTutorApi';

export interface IFreeTutor {
  freeSlot: number;
  tutors: IUser[];
}
export interface IFreeTutorState {
  status: 'idle' | 'loading' | 'rejected';
  list: Array<IFreeTutor>;
}

const initialState: IFreeTutorState = {
  status: 'idle',
  list: [],
};

export const FreeTutorSlice = createSlice({
  name: 'freeTutor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getFreeTutors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFreeTutors.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getFreeTutors.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getFreeTutorState = (state: RootState): RootState['freeTutor'] =>
  state.freeTutor;

export * from './FreeTutorApi';
export default FreeTutorSlice.reducer;
