import React from 'react';
import { Box, Tabs, Tab, Chip } from '@mui/material';
import { ICountryRate } from '../../../store/FreeSlotSelectSlice';

interface IGroupClassToggle {
  onToggle: (value) => void;
  rate: ICountryRate | undefined;
}

const GroupClassToggle: React.FC<IGroupClassToggle> = ({ onToggle, rate }) => {
  const [value, setValue] = React.useState('groupClass');

  const handleChange = (event, newValue) => {
    onToggle(newValue);
    setValue(newValue);
  };
  return (
    <>
      <Box borderRadius={50} style={{ background: '#f7f7f7', margin: '20px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          // aria-label="wrapped label tabs example"
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            icon={
              <Chip
                label={
                  rate && rate.unlimited_rate
                    ? `${rate.currency} ${rate.unlimited_rate}/hour`
                    : ''
                }
                size="small"
                sx={{ borderRadius: '5px', background: '#ffbf77' }}
              />
            }
            iconPosition="end"
            value="groupClass"
            label="Group Class"
            sx={{
              borderRadius: '50px',
              fontSize: '18px',
              '& .MuiTab-wrapper > :not(style) + :not(style)': {
                marginLeft: 1, // Adjust spacing between icon (Chip) and label as needed
              },
              '&.Mui-selected': { background: '#00b9af', color: 'white' },
              minHeight: '50px',
            }}
          />
          <Tab
            icon={
              <Chip
                label={
                  rate && rate.one_to_one_rate
                    ? `${rate.currency} ${rate.one_to_one_rate}/hour`
                    : ''
                }
                size="small"
                sx={{ borderRadius: '5px', background: '#ffbf77' }}
              />
            }
            iconPosition="end"
            value="oneOnOneClass"
            label="1 On 1 Class"
            sx={{
              borderRadius: '50px',
              fontSize: '18px',
              '& .MuiTab-wrapper > :not(style) + :not(style)': {
                marginLeft: 1, // Adjust spacing between icon (Chip) and label as needed
              },
              '&.Mui-selected': { background: '#00b9af', color: 'white' },
              minHeight: '50px',
            }}
          />
        </Tabs>
      </Box>
    </>
  );
};

export default GroupClassToggle;
