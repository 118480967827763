import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  CardContentProps,
  Typography,
} from '@mui/material';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

export interface TimeSlotCardProps {
  title: string;
  subTitle?: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
}

// STYLES
const contentStyles: CardContentProps['sx'] = {
  backgroundColor: TP_COLORS.paperGray.light,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const TimeSlotCard: React.FC<TimeSlotCardProps> = ({
  title,
  subTitle,
  onClick,
  selected = false,
  disabled = false,
}) => {
  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <CardContent
          sx={{
            ...contentStyles,
            backgroundColor: selected
              ? 'primary.main'
              : TP_COLORS.paperGray.light,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={500}
            color={selected ? TP_COLORS.white : 'secondary'}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant="subtitle2"
              fontWeight={600}
              color={selected ? TP_COLORS.white : TP_COLORS.black}
            >
              Booked
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TimeSlotCard;
