import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';
import { ICurriculum } from '.';

const CurriculumApiDataProvider = new APIDataProvider<ICurriculum>();

export const getCurriculums = createAsyncThunk('curriculum/list', async () => {
  const response = await CurriculumApiDataProvider.get(ApiPaths.curriculums);
  return response.data;
});

export default getCurriculums;
