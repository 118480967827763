/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import { signupUser } from './SignupApi';
// HELPER
import { setTutopiyaPlatformTokenToLocalStorage } from '../../helper';

export interface ISignupUser {
  id?: string;
  userName?: string;
  name?: string;
  email?: string;
  gender?: 'Male' | 'Female';
  phoneNumber?: string;
  curriculum?: string;
  subject?: string;
  grade?: string;
  password?: string;
  examDate?: number;
  findUs?: string;
  urlTracking?: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_adgroup?: string;
    utm_term?: string;
    utm_content?: string;
    lastUpdated?: number;
  };
  country?: string;
  salesAgent?: string;
}
export interface IUser {
  id: string;
  firstName: string;
  lastName?: string;
  avatar?: string;
  subject: string;
  curriculum: string;
  grade: string;
  country?: string;
}

export interface ISignUpState {
  status: 'idle' | 'loading' | 'rejected';
  user: ISignupUser | undefined;
  signInToken: string | undefined;
  platformSignInToken: string | undefined;
}

const initialState: ISignUpState = {
  status: 'idle',
  user: undefined,
  signInToken: undefined,
  platformSignInToken: undefined,
};

export const SignupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    updateToken(state, action: PayloadAction<string>) {
      state.platformSignInToken = action.payload;
      setTutopiyaPlatformTokenToLocalStorage(action.payload);
    },
  },
  extraReducers: (builder) => {
    // list
    builder
      // SIGNUP SLICE
      .addCase(signupUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = {
          ...action.payload,
          password: action.meta.arg.password,
        };
      })
      .addCase(signupUser.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getSignupState = (state: RootState): RootState['signUp'] =>
  state.signUp;

export const { updateToken } = SignupSlice.actions;
export * from './SignupApi';
export default SignupSlice.reducer;
