export interface ISafeAreaGaps {
  top: string;
  left: string;
  right: string;
  bottom: string;
}

export const SafeAreaGaps: ISafeAreaGaps = {
  top: 'env(safe-area-inset-top)',
  left: 'env(safe-area-inset-left)',
  right: 'env(safe-area-inset-right)',
  bottom: 'env(safe-area-inset-bottom)',
};

export const isMobile = false;
