import { createSlice, isRejected } from '@reduxjs/toolkit';
import { RootState } from '..';
import { removeAuthTokenFromLocalStorage } from '../../helper';

export interface IErrorState {
  visible: boolean;
  message: string;
}

const initialState: IErrorState = {
  visible: false,
  message: '',
};

export const ErrorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.visible = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      state.visible = true;
      const err = JSON.parse(action.error.message as string);
      state.message =
        err.data && err.data.message
          ? err.data.message
          : 'Something went wrong';

      if (err.status === 401) {
        removeAuthTokenFromLocalStorage();
        window.location.href = '/';
      }
    });
  },
});

export const { resetMessage } = ErrorSlice.actions;

export const getErrorState = (state: RootState): RootState['error'] =>
  state.error;

export default ErrorSlice.reducer;
