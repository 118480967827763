import { createTheme } from '@mui/material';
import {
  LightColorPaletteOptions,
  DarkColorPaletteOptions,
} from './ColorPalettes';

export const LightTheme = createTheme({
  palette: LightColorPaletteOptions,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export const DarkTheme = createTheme({
  palette: DarkColorPaletteOptions,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
