import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { IGrade } from '../CurriculumSlice';
// API
import { getGrades } from './GradeApi';

export interface IGradeState {
  status: 'idle' | 'loading' | 'rejected';
  list: Array<IGrade>;
}

const initialState: IGradeState = {
  status: 'idle',
  list: [],
};

export const GradeSlice = createSlice({
  name: 'grade',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getGrades.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGrades.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getGrades.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getGradeState = (state: RootState): RootState['grade'] =>
  state.grade;

export * from './GradeApi';
export default GradeSlice.reducer;
