import { Box, Stack, BoxProps, StackProps, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FullScreenLoader from '../../components/Loaders/FullScreenloader';
import {
  isMobile,
  SafeAreaGaps,
  getTutopiyaPlatformTokenFromLocalStorage,
} from '../../helper';
import ExploreMoreCard from './ExploreMoreCard';

// STYLES
const contentContainerHeight = isMobile
  ? undefined
  : { xs: 'unset', sm: 'unset', md: 'unset', lg: 'calc(100% - 184.97px)' };

const containerStyles: BoxProps['sx'] = {
  height: '100vh',
  overflowY: 'auto',
  background: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: `url('/images/backgrounds/bg-lg.png') center/101% 100%`,
  },
  p: {
    xs: '15px',
    lg: 'unset',
  },
};

const contentContainerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  height: contentContainerHeight,
  display: 'flex',
  justifyContent: 'center',
};

const mobileImageContainerStyles: StackProps['sx'] = {
  backgroundColor: 'background.default',
  position: 'fixed',
  top: SafeAreaGaps.top,
  width: '100%',
  left: 0,
  p: '15px 0',
  display: { xs: 'flex', lg: 'none' },
  zIndex: 1,
};

const ExploreMore: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  const platformToken = getTutopiyaPlatformTokenFromLocalStorage();
  const baseUrl = process.env.REACT_APP_TUTOPIYA_PLATFORM_BASE_URL as string;
  const quizUrl = platformToken
    ? `${baseUrl}/#/jwtLogin?id_token=${platformToken}&quiztopiya=true`
    : baseUrl;

  const platformUrl = platformToken
    ? `${baseUrl}/#/jwtLogin?id_token=${platformToken}`
    : baseUrl;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  if (isLoading) {
    <FullScreenLoader />;
  }

  return (
    <Box sx={containerStyles}>
      <Stack
        direction="row"
        justifyContent="center"
        sx={mobileImageContainerStyles}
      >
        <img
          src="/images/logos/tp-logo-green.png"
          alt="tp-logo"
          width="120px"
        />
      </Stack>
      <Box sx={{ minHeight: '85px', display: { xs: 'block', lg: 'none' } }} />
      {!isMobile && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <img
            src="/images/logos/tp-logo-text-white.png"
            alt="tp-logo"
            style={{ width: '150px', marginTop: '20px' }}
          />
          <img
            src="/images/shapes/wave.png"
            alt="wave-shape"
            style={{ width: '100%', marginTop: '20px', marginBottom: '-2px' }}
          />
        </Stack>
      )}
      <Box sx={contentContainerStyles}>
        <Grid
          container
          spacing={2}
          style={{ maxWidth: '1200px', marginBottom: '45px' }}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ExploreMoreCard
              title="Live Online Tuition"
              src="/images/avatars/platform_3.png"
              text="Access and book an online tuition with one of our experienced tutors"
              redirectUrl={platformUrl}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <ExploreMoreCard
              title="Learning Platform"
              src="/images/avatars/quiztopiya_3.png"
              text="Evaluate yourself using our question bank"
              redirectUrl={quizUrl}
              target="_blank"
              theme="dark"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ExploreMore;
