import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Steps from '../Onboarding/steps';

const Trial: React.FC = () => {
  return (
    <Routes>
      <Route path="/steps" element={<Steps pageType="trial" />} />
    </Routes>
  );
};

export default Trial;
