import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, IconButton, Snackbar, SxProps } from '@mui/material';
// ICONS
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import { getErrorState, resetMessage } from '../../store/ErrorSlice';

interface IAlertData {
  visible: boolean;
  message: string;
}

const contentStyles: SxProps = {
  backgroundColor: 'rgb(253, 237, 237)',
  color: 'rgb(239, 83, 80)',
};
const initialState: IAlertData = {
  visible: false,
  message: '',
};

const ErrorAlert: React.FC = () => {
  // HOOKS
  const dispatch = useDispatch();
  const errorState = useAppSelector(getErrorState);

  // LOCAL STATES
  const [alertData, setAlertData] = useState<IAlertData>({ ...initialState });

  // USE EFFECTS
  useEffect(() => {
    if (errorState.visible) {
      setAlertData({
        visible: errorState.visible,
        message: errorState.message,
      });
    }
  }, [errorState.visible]);

  // METHODS
  const handleClose = () => {
    setAlertData((prevState) => ({
      ...prevState,
      visible: false,
    }));
    dispatch(resetMessage());
  };

  // RENDER
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={alertData.visible}
      onClose={handleClose}
      autoHideDuration={4000}
      TransitionProps={{ onExited: () => handleClose() }}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    >
      <Alert
        onClose={handleClose}
        severity="error"
        icon={<ErrorOutlineIcon sx={{ color: 'rgb(239, 83, 80)' }} />}
        sx={contentStyles}
      >
        {alertData.message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorAlert;
