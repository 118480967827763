import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import moment from 'moment';
import Moment from 'moment-timezone';
import JWT from 'jsonwebtoken';
import _ from 'lodash';
// ICONS
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import { getSettingsState } from '../../../store/settings';
import { getSignupState, updateToken } from '../../../store/SignupSlice';
import {
  getFreeSlotSelectState,
  getSelectedFreeSlots,
} from '../../../store/FreeSlotSelectSlice';
import { createTrialClass, ICreateTrial } from '../../../store/TrialSlice';
import { getTrialUserState } from '../../../store/TrialUserSlice';
// COMPONENTS
import BasicLoader from '../../../components/Loaders/BasicLoader';
import TimeSlotCard from '../components/TimeSlotCard';
import GroupClassToggle from '../components/groupClassToggle';
// MODALS
import TimezoneModal from '../modals/TimezoneModal';
// CONSTANTS
import { Countries, ICountry } from '../../../constants';
import GroupClassCard from '../components/groupClassCard';

interface ITimeSlot {
  start: string;
}

interface ICustomFreeSlot {
  start: string;
  end?: string;
  userId?: Array<string>;
  groupClassId?: string;
  groupClassTopicId?: string;
}

interface ISelectedTimeSlot {
  timestamp: number;
  userId?: Array<string> | undefined;
  groupClassId?: string;
  groupClassTopicId?: string;
}

interface Step5Props {
  onTabChange: () => void;
  pageType: string;
}

const containerStyles: React.CSSProperties = {
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const defaultUserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : 'Asia/Singapore';

let defaultTimezone: ICountry = {
  countryCode: 'SG',
  name: 'Singapore - Asia/Singapore',
  value: 'Asia/Singapore',
};

const userTimezone = Countries.find(
  (country) => country.value === defaultUserTimezone
);

if (userTimezone) {
  defaultTimezone = userTimezone;
}

const timeSlots: Array<ITimeSlot> = [
  { start: '08:00:00 AM' },
  { start: '09:00:00 AM' },
  { start: '10:00:00 AM' },
  { start: '11:00:00 AM' },
  { start: '12:00:00 PM' },
  { start: '01:00:00 PM' },
  { start: '02:00:00 PM' },
  { start: '03:00:00 PM' },
  { start: '04:00:00 PM' },
  { start: '05:00:00 PM' },
  { start: '06:00:00 PM' },
  { start: '07:00:00 PM' },
  { start: '08:00:00 PM' },
  { start: '09:00:00 PM' },
  { start: '10:00:00 PM' },
  { start: '11:00:00 PM' },
];

const sortFreeSlots = (a: ICustomFreeSlot, b: ICustomFreeSlot) =>
  moment(a.start).toDate().getTime() - moment(b.start).toDate().getTime();

const freeSlotCheckingDayRange = process.env
  .REACT_APP_FREESLOT_CHECKING_DAY_RANGE
  ? parseFloat(process.env.REACT_APP_FREESLOT_CHECKING_DAY_RANGE)
  : 3;

const Step5: React.FC<Step5Props> = ({ onTabChange, pageType }) => {
  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', 'slot-select');
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);

  // STORE
  const freeSlotState = useAppSelector(getFreeSlotSelectState);
  const settingsState = useAppSelector(getSettingsState);
  const signupState = useAppSelector(getSignupState);
  const trialUserState = useAppSelector(getTrialUserState);

  // STATES
  const [isTimezoneModalOpen, setTimezoneModalOpen] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTimeSlots, setSelectedTimeSlot] = useState<
    ISelectedTimeSlot[]
  >([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [isTokenLoading, setTokenLoading] = useState(false);
  const [salesAgent, setSalesAgent] = useState('');
  const [
    subjectMapSettingDataAllowed,
    setSubjectMapSettingDataAllowed,
  ] = useState<boolean | undefined>(undefined);
  const [loadedGroupClassListOffset, setGroupClassListOffset] = useState(0);
  const [slotType, setSlotType] = useState('');
  const loadedGroupClassListLimit = 5;

  const urlSearcharams = new URLSearchParams(location.search);
  const urlAgentId = urlSearcharams.get('agentId');
  const { onboardingSubjectSettingsData } = settingsState;
  const signUpUser = signupState.user;
  const trialUser = trialUserState.user;
  const isLoading =
    signupState.status === 'loading' ||
    freeSlotState.status === 'loading' ||
    trialUserState.status === 'loading' ||
    settingsState.status === 'loading';

  const isAutoBookEnabled = subjectMapSettingDataAllowed || false;

  const countryRates =
    freeSlotState && freeSlotState.list && freeSlotState.list.rate
      ? freeSlotState.list.rate
      : undefined;

  let freeSlotList: ICustomFreeSlot[] = [];

  if (!isLoading) {
    if (isAutoBookEnabled === true) {
      const filterFreeSlots = (item: ICustomFreeSlot) => {
        return (
          moment(item.start).format('YYYY-MM-DD') ===
            selectedDate.format('YYYY-MM-DD') &&
          moment().toDate().getTime() < moment(item.start).toDate().getTime()
        );
      };

      if (
        freeSlotState.list &&
        freeSlotState.list.type &&
        freeSlotState.list.type !== slotType
      ) {
        setSlotType(freeSlotState.list.type);
      }
      if (
        freeSlotState.list &&
        freeSlotState.list.type &&
        freeSlotState.list.type !== 'groupClass' &&
        slotType &&
        freeSlotState.list.type !== slotType
      ) {
        setSlotType(freeSlotState.list.type);
        setSubjectMapSettingDataAllowed(undefined);
      }

      if (slotType === 'groupClass') {
        freeSlotList = [...freeSlotState.list.slots].sort(
          (a, b) =>
            moment(a.start).toDate().getTime() -
            moment(b.start).toDate().getTime()
        );

        freeSlotList = freeSlotList.slice(
          0,
          loadedGroupClassListOffset + loadedGroupClassListLimit
        );
      } else {
        freeSlotList = freeSlotState.list.slots.filter(filterFreeSlots);
      }
    }

    if (isAutoBookEnabled === false) {
      timeSlots.forEach((item) => {
        const _start = Moment(
          `${selectedDate.format('YYYY-MM-DD')} ${item.start}`,
          'YYYY-MM-DD hh:mm A'
        ).tz(selectedTimezone.value);

        const _end = moment(_start).add(1, 'hour');

        if (
          _start.format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD') &&
          moment().add(1, 'day').toDate().getTime() < _start.toDate().getTime()
        ) {
          freeSlotList.push({
            start: _start.format(),
            end: _end.format(),
          });
        }
      });
    }
  }

  // USE EFFECT
  useEffect(() => {
    if (urlAgentId) {
      setSalesAgent(urlAgentId);
    }

    if (trialUser && trialUser.country && pageType === 'trial') {
      const match = Countries.find((item) => item.value === trialUser.country);

      if (match) {
        setSelectedTimezone(match);
      }
    }

    if (signUpUser && signUpUser.country && pageType === 'onboarding') {
      const match = Countries.find((item) => item.value === signUpUser.country);

      if (match) {
        setSelectedTimezone(match);
      }
    }
  }, []);

  useEffect(() => {
    if (
      signupState.status === 'rejected' ||
      trialUserState.status === 'rejected'
    ) {
      onTabChange();
    }
  }, [signupState.status, trialUserState.status]);

  useEffect(() => {
    if (signUpUser && signUpUser.userName && signUpUser.password) {
      setTokenLoading(true);
      JWT.sign(
        {
          userName: signUpUser.userName.trim(),
          password: signUpUser.password,
        },
        process.env.REACT_APP_TUTOPIYA_SIGN_IN_TOKEN_PRIVATE_KEY,
        (err, token) => {
          if (err) {
            // eslint-disable-next-line  no-console
            console.error('jwt failed', err);
            setTokenLoading(false);
          } else {
            dispatch(updateToken(token));
            setTokenLoading(false);
          }
        }
      );
    }
  }, [signUpUser]);

  useEffect(() => {
    if (
      onboardingSubjectSettingsData &&
      onboardingSubjectSettingsData.isAllowed &&
      signUpUser &&
      signUpUser.subject &&
      signUpUser.curriculum &&
      signUpUser.grade &&
      signUpUser.id
    ) {
      dispatch(
        getSelectedFreeSlots({
          subject: signUpUser.subject,
          curriculum: signUpUser.curriculum,
          grade: signUpUser.grade,
          date: Moment()
            .tz(selectedTimezone.value)
            .add(1, 'day')
            .utc()
            .format(),
          studentId: signUpUser.id,
          studentCountryOverRide: salesAgent
            ? selectedTimezone.value
            : undefined,
          type: slotType,
        })
      );
    }
  }, [onboardingSubjectSettingsData, signUpUser]);

  useEffect(() => {
    if (
      pageType === 'trial' &&
      onboardingSubjectSettingsData &&
      onboardingSubjectSettingsData.isAllowed &&
      trialUser &&
      trialUserState.subject &&
      trialUser.curriculum &&
      trialUser.grade
    ) {
      dispatch(
        getSelectedFreeSlots({
          subject: trialUserState.subject,
          curriculum: trialUser.curriculum,
          grade: trialUser.grade,
          date: Moment()
            .tz(selectedTimezone.value)
            .add(1, 'day')
            .utc()
            .format(),
          studentId: trialUser.id,
          studentCountryOverRide: salesAgent
            ? selectedTimezone.value
            : undefined,
          type: slotType,
        })
      );
    }
  }, [onboardingSubjectSettingsData, trialUser]);

  useEffect(() => {
    if (
      freeSlotState.list &&
      freeSlotState.list.slots &&
      freeSlotState.list.slots.length &&
      isAutoBookEnabled === true
    ) {
      const sortedList =
        slotType !== 'groupClass'
          ? _.orderBy(
              freeSlotState.list.slots.filter(
                (item) =>
                  moment(item.start).format('YYYY-MM') ===
                  selectedDate.format('YYYY-MM')
              ),
              'start',
              ['asc']
            )
          : freeSlotState.list.slots;

      if (sortedList.length) {
        setSelectedDate(moment(sortedList[0].start).startOf('day'));
      }
    }
  }, [freeSlotState.list, isAutoBookEnabled]);

  useEffect(() => {
    if (
      subjectMapSettingDataAllowed === undefined &&
      onboardingSubjectSettingsData &&
      (freeSlotState.list.slots || []).length
    ) {
      let _subjectMapSettingDataAllowed =
        onboardingSubjectSettingsData.isAllowed;

      if (!(freeSlotState.list.slots || []).length) {
        _subjectMapSettingDataAllowed = false;
      } else if ((freeSlotState.list.slots || []).length) {
        const hasFreeSlotsWithExpectedDateRange =
          freeSlotState.list.type !== 'groupClass'
            ? freeSlotState.list.slots.some(
                (item) =>
                  moment(item.start) <=
                    moment().add(freeSlotCheckingDayRange, 'days') &&
                  moment(item.start) > moment()
              )
            : freeSlotState.list.slots.length > 0;

        if (!hasFreeSlotsWithExpectedDateRange) {
          _subjectMapSettingDataAllowed = false;
        }
      }

      setSubjectMapSettingDataAllowed(_subjectMapSettingDataAllowed);
    }
  }, [freeSlotState.list, onboardingSubjectSettingsData]);

  useEffect(() => {
    if (
      isAutoBookEnabled === false &&
      !freeSlotList.length &&
      selectedDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    ) {
      setSelectedDate(selectedDate.clone().add(1, 'day'));
    }
  }, [isAutoBookEnabled]);

  // METHODS
  const handleSkipClick = () => {
    navigate('/onboarding/exploreMore');
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      setSelectedDate(date);
    }
  };

  const onMonthChange = (date: moment.Moment) => {
    const newDate = moment(date.format('YYYY-MM-15'), 'YYYY-MM-DD');
    if (newDate) {
      if (isAutoBookEnabled === true) {
        if (
          pageType === 'onboarding' &&
          signUpUser &&
          signUpUser.subject &&
          signUpUser.curriculum &&
          signUpUser.grade &&
          signUpUser.id
        ) {
          dispatch(
            getSelectedFreeSlots({
              subject: signUpUser.subject,
              curriculum: signUpUser.curriculum,
              grade: signUpUser.grade,
              date: Moment(newDate).tz(selectedTimezone.value).utc().format(),
              studentId: signUpUser.id,
              studentCountryOverRide: salesAgent
                ? selectedTimezone.value
                : undefined,
              type: slotType,
            })
          );
        } else if (
          pageType === 'trial' &&
          trialUser &&
          trialUserState.subject &&
          trialUser.curriculum &&
          trialUser.grade &&
          trialUser.id
        ) {
          dispatch(
            getSelectedFreeSlots({
              subject: trialUserState.subject as string,
              curriculum: trialUser.curriculum,
              grade: trialUser.grade,
              date: Moment(newDate).tz(selectedTimezone.value).utc().format(),
              studentId: trialUser.id,
              studentCountryOverRide: salesAgent
                ? selectedTimezone.value
                : undefined,
              type: slotType,
            })
          );
        }
      }
    }

    if (isAutoBookEnabled === true) {
      setSelectedDate(newDate);
    }
  };

  const handleDeleteSlot = (slot: number) => {
    const _timeSlots = selectedTimeSlots.filter((s) => slot !== s.timestamp);
    setSelectedTimeSlot(_timeSlots);
  };

  const handleTimeSlotChange = async (
    timestamp: number,
    userId?: Array<string> | undefined,
    groupClassId?: string | undefined,
    groupClassTopicId?: string | undefined
  ) => {
    if (isAutoBookEnabled && selectedTimeSlots.length) {
      await confirm({
        hideCancelButton: true,
        title: 'Free slot limit exceeded',
        description: `Please note you can't select more than one free slot.`,
        confirmationText: 'Ok',
      });

      return;
    }

    setSelectedTimeSlot((prevState) => {
      const found = prevState.find((x) => x.timestamp === timestamp);

      if (found) {
        return [
          ...prevState.filter(
            (timestampSlot) => timestampSlot.timestamp !== timestamp
          ),
        ];
      }

      if (prevState.length === 3) {
        setAlertOpen(true);
        return prevState;
      }

      return [
        ...prevState,
        {
          timestamp: Moment(timestamp)
            .tz(selectedTimezone.value)
            .toDate()
            .getTime(),
          userId,
          groupClassId,
          groupClassTopicId,
        },
      ];
    });
  };

  const handleGroupClassLoadMore = () => {
    freeSlotList = freeSlotList.concat(
      freeSlotState.list.slots.slice(
        0,
        loadedGroupClassListOffset + loadedGroupClassListLimit
      )
    );
    setGroupClassListOffset(
      loadedGroupClassListOffset + loadedGroupClassListLimit
    );
  };

  const handleCountryChange = (country: ICountry) => {
    const newDate = moment(selectedDate.format('YYYY-MM-15'), 'YYYY-MM-DD');

    setSelectedTimezone(country);
    setTimezoneModalOpen(false);

    if (
      onboardingSubjectSettingsData &&
      onboardingSubjectSettingsData.isAllowed
    ) {
      if (
        pageType === 'onboarding' &&
        signUpUser &&
        signUpUser.subject &&
        signUpUser.curriculum &&
        signUpUser.grade &&
        signUpUser.id
      ) {
        dispatch(
          getSelectedFreeSlots({
            subject: signUpUser.subject,
            curriculum: signUpUser.curriculum,
            grade: signUpUser.grade,
            date: Moment(newDate).tz(country.value).utc().format(),
            studentId: signUpUser.id,
            studentCountryOverRide: salesAgent ? country.value : undefined,
            type: slotType,
          })
        );
      } else if (
        pageType === 'trial' &&
        trialUser &&
        trialUserState.subject &&
        trialUser.curriculum &&
        trialUser.grade &&
        trialUser.id
      ) {
        dispatch(
          getSelectedFreeSlots({
            subject: trialUserState.subject as string,
            curriculum: trialUser.curriculum,
            grade: trialUser.grade,
            date: Moment(newDate).tz(country.value).utc().format(),
            studentId: trialUser.id,
            studentCountryOverRide: salesAgent ? country.value : undefined,
            type: slotType,
          })
        );
      }
    }
  };

  const checkCountry = async () => {
    let user = signUpUser;
    let updateCountry = false;

    if (pageType === 'trial') {
      user = trialUser;
    }

    if (user && user.country !== selectedTimezone.value && urlAgentId) {
      await confirm({
        title: 'Are you sure?',
        description: `Do you want to update the student country to ${selectedTimezone.value}?`,
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
        .then(() => {
          updateCountry = true;
        })
        .catch(() => {
          updateCountry = false;
        });
    }

    handleSubmit(updateCountry);
  };

  const handleSubmit = (updateCountry = false) => {
    let userId: string | undefined;

    if (signUpUser && signUpUser.id) {
      userId = signUpUser.id;
    } else if (trialUser && trialUser.id) {
      userId = trialUser.id;
    }

    if (userId && selectedTimeSlots.length > 0) {
      const selectedSlot = selectedTimeSlots[0];

      let API_DATA: ICreateTrial;

      if (pageType === 'onboarding') {
        API_DATA = {
          userId,
          selectedTime: selectedSlot.timestamp,
          freeSlots: [],
          type: 'New Lead',
          groupClassId: selectedSlot.groupClassId,
          groupClassTopicId: selectedSlot.groupClassTopicId,
        };
      } else {
        API_DATA = {
          userId,
          selectedTime: selectedSlot.timestamp,
          freeSlots: [],
          subject: trialUserState.subject,
          curriculum: trialUser && trialUser.curriculum,
          type: 'Additional Trial',
          salesAgent,
          groupClassId: selectedSlot.groupClassId,
          groupClassTopicId: selectedSlot.groupClassTopicId,
        };
      }

      selectedTimeSlots.forEach((timeSlot) => {
        API_DATA.freeSlots?.push(timeSlot.timestamp);
      });

      if (
        selectedSlot &&
        selectedSlot.userId &&
        selectedSlot.userId.length > 0
      ) {
        // eslint-disable-next-line prefer-destructuring
        API_DATA.tutorId = selectedSlot.userId[0];
      }

      if (updateCountry) {
        API_DATA.overRideStudentCountry = selectedTimezone.value;
      }

      dispatch(createTrialClass(API_DATA));
    }
  };

  const onGroupClassToggle = (type) => {
    if (
      pageType === 'onboarding' &&
      signUpUser &&
      signUpUser.subject &&
      signUpUser.curriculum &&
      signUpUser.grade &&
      signUpUser.id
    ) {
      freeSlotList = [];
      dispatch(
        getSelectedFreeSlots({
          subject: signUpUser.subject,
          curriculum: signUpUser.curriculum,
          grade: signUpUser.grade,
          date: Moment(selectedDate).tz(selectedTimezone.value).utc().format(),
          studentId: signUpUser.id,
          type,
          studentCountryOverRide: salesAgent
            ? selectedTimezone.value
            : undefined,
        })
      );
    } else if (
      pageType === 'trial' &&
      trialUser &&
      trialUserState.subject &&
      trialUser.curriculum &&
      trialUser.grade &&
      trialUser.id
    ) {
      freeSlotList = [];
      dispatch(
        getSelectedFreeSlots({
          subject: trialUserState.subject as string,
          curriculum: trialUser.curriculum,
          grade: trialUser.grade,
          date: Moment(selectedDate).tz(selectedTimezone.value).utc().format(),
          studentId: trialUser.id,
          type,
          studentCountryOverRide: salesAgent
            ? selectedTimezone.value
            : undefined,
        })
      );
    }
  };

  // RENDER
  const RENDER_FREE_SLOTS = () => {
    if (!isLoading && freeSlotList && freeSlotList.length) {
      return (
        <div
          style={{
            marginBottom: '15px',
            padding: '0 30px',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            {freeSlotList.sort(sortFreeSlots).map((slot, index) => {
              const _timeSlot = Moment(slot.start).tz(selectedTimezone.value);
              const _timeSlotTimestamp = Moment(slot.start)
                .tz(selectedTimezone.value)
                .toDate()
                .getTime();

              const selected = selectedTimeSlots.some(
                (s) => s.timestamp === _timeSlotTimestamp
              );
              return (
                <>
                  {slotType !== 'groupClass' && (
                    <Grid key={index} item xs={4} lg={3}>
                      <TimeSlotCard
                        disabled={isLoading}
                        selected={selected}
                        title={_timeSlot.format('hh:mm A')}
                        onClick={() =>
                          handleTimeSlotChange(_timeSlotTimestamp, slot.userId)
                        }
                      />
                    </Grid>
                  )}
                  {slotType === 'groupClass' && (
                    <Grid key={index} item xs={12} direction="column">
                      <GroupClassCard
                        disabled={isLoading}
                        selected={selected}
                        title={_timeSlotTimestamp}
                        selectedTimezone={selectedTimezone}
                        onClick={() =>
                          handleTimeSlotChange(
                            _timeSlotTimestamp,
                            undefined,
                            slot.groupClassId,
                            slot.groupClassTopicId
                          )
                        }
                      />
                    </Grid>
                  )}
                </>
              );
            })}
            <Grid item xs={12}>
              {slotType === 'groupClass' &&
                freeSlotList.length > loadedGroupClassListOffset + 1 && (
                  <Button
                    fullWidth
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={handleGroupClassLoadMore}
                  >
                    Load More
                  </Button>
                )}
            </Grid>
          </Grid>
        </div>
      );
    }

    if (!isLoading && freeSlotList.length === 0) {
      return (
        <div
          style={{
            padding: '0 30px',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <div>
            <Typography variant="h6" fontWeight={600}>
              No Slots Available
            </Typography>
          </div>
          <div>
            <Typography fontWeight={500}>Please try another date</Typography>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderButtons = () => {
    switch (pageType) {
      case 'onboarding':
        return (
          <div
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid container spacing={2} style={{ maxWidth: '600px' }}>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="small"
                  color="inherit"
                  onClick={handleSkipClick}
                >
                  Skip
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={selectedTimeSlots.length === 0}
                  onClick={checkCountry}
                  loading={isLoading || isTokenLoading}
                  className="onboarding-trial-booked"
                >
                  Next
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        );

      case 'trial':
        return (
          <div
            className="checking-here"
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={6} style={{ width: '300px' }}>
              <LoadingButton
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                disabled={selectedTimeSlots.length === 0}
                onClick={checkCountry}
                loading={isLoading || isTokenLoading}
              >
                Next
              </LoadingButton>
            </Grid>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <Typography variant="h5" fontWeight={600}>
            Date & Time
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color="secondary">
            Please select an option(s) for your class.
          </Typography>
          {/* <GroupClassToggle onToggle={onGroupClassToggle} /> */}
          {slotType && slotType !== 'freeSlot' && (
            <GroupClassToggle
              onToggle={onGroupClassToggle}
              rate={countryRates}
            />
          )}
        </div>

        {!isLoading && (
          <div style={{ padding: '0 30px', marginBottom: '30px' }}>
            <Stack direction="row" justifyContent="space-between">
              {slotType !== 'groupClass' && (
                <Typography fontWeight={500}>
                  Select Date & Time Slot
                </Typography>
              )}
              {slotType === 'groupClass' && (
                <Typography fontWeight={500}>Select 1 Time Slot</Typography>
              )}
              <Button
                size="small"
                color="primary"
                endIcon={<ArrowDropDownIcon />}
                onClick={() => setTimezoneModalOpen(true)}
              >
                {selectedTimezone.value}
              </Button>
            </Stack>

            {slotType !== 'groupClass' && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        label="Select Date & Time Slot"
                        value={selectedDate}
                        minDate={moment()}
                        onChange={handleDateChange}
                        inputFormat="YYYY/MM/DD"
                        onMonthChange={onMonthChange}
                        DialogProps={{
                          onClose: undefined,
                        }}
                        shouldDisableYear={() =>
                          freeSlotState.status === 'loading'
                        }
                        componentsProps={
                          freeSlotState.status === 'loading'
                            ? {
                                leftArrowButton: {
                                  disabled: true,
                                },
                                rightArrowButton: {
                                  disabled: true,
                                },
                                actionBar: {
                                  actions: ['accept'],
                                },
                              }
                            : {
                                actionBar: {
                                  actions: ['accept'],
                                },
                              }
                        }
                        shouldDisableDate={(day) => {
                          const _date = day.format('YYYY-MM-DD');

                          if (subjectMapSettingDataAllowed === true) {
                            if (
                              freeSlotState.list &&
                              freeSlotState.list.slots &&
                              freeSlotState.list.slots.length &&
                              freeSlotState.list.slots.some(
                                (item) =>
                                  moment(item.start).format('YYYY-MM-DD') ===
                                  _date
                              )
                            ) {
                              return false;
                            }

                            return true;
                          }

                          return false;
                        }}
                        shouldDisableMonth={() => true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label={undefined}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DateRangeIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </div>
        )}

        {!isLoading &&
          subjectMapSettingDataAllowed === true &&
          freeSlotList &&
          freeSlotList.length <= 4 &&
          freeSlotList.length > 0 && (
            <div style={{ marginBottom: '15px', padding: '0 30px' }}>
              <Card>
                <CardContent
                  style={{ padding: '4px' }}
                  sx={{ backgroundColor: '#017771' }}
                >
                  <Typography
                    color="white"
                    fontWeight={500}
                    variant="subtitle2"
                    padding="5px"
                    textAlign="center"
                  >
                    Hurry up! Limited slots available
                  </Typography>
                </CardContent>
              </Card>
            </div>
          )}

        {isLoading && (
          <div style={{ marginBottom: '15px' }}>
            <BasicLoader />
          </div>
        )}

        {selectedTimeSlots.length > 0 && (
          <div style={{ padding: '0 30px' }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ mb: '30px', flexWrap: 'wrap' }}
            >
              {selectedTimeSlots.map((slot, index) => {
                const _date = Moment.tz(slot.timestamp, selectedTimezone.value);
                const _lbl = `${_date.format('MMM DD')} ${_date.format(
                  'hh:mm A'
                )}`;
                return (
                  <Chip
                    key={index}
                    label={_lbl}
                    sx={{ mr: '5px', mb: '5px', fontSize: '12px' }}
                    onDelete={() => handleDeleteSlot(slot.timestamp)}
                  />
                );
              })}
            </Stack>
          </div>
        )}
        {RENDER_FREE_SLOTS()}
      </div>

      {/* GAP */}
      <div style={{ minHeight: '15px' }} />
      {renderButtons()}
      {/* MODALS */}
      {selectedTimezone && isTimezoneModalOpen && (
        <TimezoneModal
          open={isTimezoneModalOpen}
          onClose={() => setTimezoneModalOpen(false)}
          selectedTimezone={selectedTimezone}
          onTimezoneChange={handleCountryChange}
        />
      )}

      {alertOpen && (
        <Dialog
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You can&apos;t select more than three slots
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen(false)}>Okay</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Step5;
