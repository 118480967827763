import React from 'react';
import moment from 'moment-timezone';
import {
  Card,
  CardContent,
  CardActionArea,
  CardContentProps,
  Typography,
  Grid,
} from '@mui/material';
// CONSTANTS
import { ICountry, TP_COLORS } from '../../../constants';

export interface TimeSlotCardProps {
  title: number;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  selectedTimezone: ICountry;
}

// STYLES
const contentStyles: CardContentProps['sx'] = {
  backgroundColor: TP_COLORS.paperGray.light,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const TimeSlotCard: React.FC<TimeSlotCardProps> = ({
  title,
  onClick,
  selected = false,
  disabled = false,
  selectedTimezone,
}) => {
  return (
    <Card elevation={0}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <CardContent
          sx={{
            ...contentStyles,
            backgroundColor: selected
              ? 'primary.main'
              : TP_COLORS.paperGray.light,
          }}
        >
          <Grid container spacing={1} direction="row">
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color={selected ? TP_COLORS.white : 'secondary'}
              >
                {moment(title).tz(selectedTimezone.value).format('dddd')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color={selected ? TP_COLORS.white : 'secondary'}
              >
                {moment(title).tz(selectedTimezone.value).format('MMM DD YYYY')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="subtitle2"
                fontWeight={500}
                color={selected ? TP_COLORS.white : 'secondary'}
              >
                {moment(title).tz(selectedTimezone.value).format('hh:mm A')}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TimeSlotCard;
