import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Box, BoxProps, Stack } from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  ISignupUser,
  getSignupState,
  signupUser,
} from '../../../store/SignupSlice';
// CONSTANTS
import { Countries } from '../../../constants';
import {
  IValidateUrlParams,
  getSettingsState,
  validateUrlParams,
} from '../../../store/settings';

interface Step1Props {
  onTabChange: (activeStep?: number) => void;
}

// STYLES
const containerStyles: React.CSSProperties = {
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const buttonStyles: BoxProps['sx'] = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0',
  backgroundColor: 'background.default',
  position: 'absolute',
  bottom: '0',
  right: '0',
  width: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '40%',
  },
};

// DATA
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : 'Asia/Singapore';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let defaultCountryCode: any = 'SG';

const defaultUserTimezone = Countries.find(
  (country) => country.value === timezone
);

if (defaultUserTimezone && defaultUserTimezone.countryCode) {
  defaultCountryCode = defaultUserTimezone.countryCode;
}

const Step1: React.FC<Step1Props> = ({ onTabChange }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', 'phone-number');
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);

  // Store
  const signupState = useAppSelector(getSignupState);
  const settingState = useAppSelector(getSettingsState);

  // State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [isRedirectReady, setRedirectReady] = useState(false);
  const [isFirstTime, setFirstTime] = useState(true);

  const { validatedUrlParamData } = settingState;
  const signUpUser = signupState.user;
  const isLoading =
    signupState.status === 'loading' || settingState.status === 'loading';

  const urlSearchParams = new URLSearchParams(location.search);
  const urlCurriculum = urlSearchParams.get('curriculum');
  const urlSubject = urlSearchParams.get('subject');
  const urlGrade = urlSearchParams.get('grade');
  const hasQueryParams = Boolean(urlCurriculum || urlSubject || urlGrade);

  useEffect(() => {
    if (signUpUser && signUpUser.phoneNumber) {
      setFirstTime(false);
      setPhoneNumber(signUpUser.phoneNumber);
    }

    if (hasQueryParams && !signUpUser) {
      const apiData: IValidateUrlParams = {};

      if (urlCurriculum) {
        apiData.curriculum = urlCurriculum;
      }

      if (urlSubject) {
        apiData.subject = urlSubject;
      }

      if (urlGrade) {
        apiData.grade = urlGrade;
      }

      if (Object.keys(apiData).length) {
        dispatch(validateUrlParams(apiData));
      }
    }
  }, []);

  useEffect(() => {
    const mobile = urlSearchParams.get('number');

    if (mobile) {
      const decodedMobile = decodeURIComponent(mobile).replace(/^\++/, '');
      setPhoneNumber(`+${decodedMobile.trim()}`);
    }

    if (
      signUpUser &&
      signUpUser.id &&
      signUpUser.phoneNumber &&
      isRedirectReady
    ) {
      if (isFirstTime && signUpUser.curriculum && signUpUser.subject) {
        onTabChange(3);
      } else if (isFirstTime && signUpUser.curriculum) {
        onTabChange(2);
      } else {
        onTabChange();
      }
    }
  }, [signUpUser]);

  const onSubmit = () => {
    setRedirectReady(true);

    const apiData: ISignupUser = {
      phoneNumber,
    };

    if (signUpUser && signUpUser.id) {
      apiData.id = signUpUser.id;
    }

    if (validatedUrlParamData && !signUpUser) {
      if (validatedUrlParamData.curriculum) {
        apiData.curriculum = validatedUrlParamData.curriculum;
      }

      if (validatedUrlParamData.subject) {
        apiData.subject = validatedUrlParamData.subject;
      }

      if (validatedUrlParamData.grade) {
        apiData.grade = validatedUrlParamData.grade;
      }
    }

    dispatch(signupUser(apiData));
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <Typography variant="h5" fontWeight={600}>
            Enter your mobile number
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color="secondary">
            Please enter your phone number to book your
            <br />
            Free Trial class
          </Typography>
        </div>
        <Stack direction="column" alignItems="center">
          <Box
            sx={{
              margin: '0 30px',
              maxWidth: '600px',
              width: { xs: '80%' },
            }}
          >
            <Typography fontWeight={500} sx={{ mb: '5px' }}>
              Phone Number
            </Typography>
            <PhoneInput
              international
              withCountryCallingCode
              defaultCountry={defaultCountryCode}
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="phone-number-input-custom-styles"
            />
          </Box>
        </Stack>
      </div>
      <div style={{ minHeight: 'calc(66.5px + 15px)' }} />
      <div>
        <Box sx={buttonStyles}>
          <LoadingButton
            disabled={
              !phoneNumber || (phoneNumber && phoneNumber.trim().length < 9)
            }
            fullWidth
            variant="contained"
            color="primary"
            sx={{ maxWidth: '300px' }}
            onClick={onSubmit}
            loading={isLoading}
            className="onboarding-phone-number-input"
          >
            Next
          </LoadingButton>
        </Box>
      </div>
    </div>
  );
};

export default Step1;
