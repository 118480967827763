import React, { HTMLAttributeAnchorTarget } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

export interface ExploreMoreCardProps {
  src: string;
  title: string;
  text: string;
  redirectUrl: string;
  theme?: string | undefined;
  isComingSoon?: boolean;
  target?: HTMLAttributeAnchorTarget | undefined;
}

const ExplareMoreCard: React.FC<ExploreMoreCardProps> = ({
  src,
  title,
  text,
  redirectUrl,
  theme,
  isComingSoon,
  target,
}) => (
  <Card
    elevation={3}
    raised
    sx={{
      borderRadius: '12px',
      backgroundColor: theme === 'dark' ? '#017771' : '',
      padding: '20px',
    }}
  >
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Typography
          variant="h5"
          fontWeight={600}
          color={theme === 'dark' ? 'common.white' : ''}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: '10px',
          }}
        >
          {title}
        </Typography>
        <Typography
          align="center"
          color={theme === 'dark' ? 'common.white' : 'secondary'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: '10px',
            minHeight: '48px',
          }}
        >
          {text}
        </Typography>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ maxWidth: '300px' }}
            disabled={isComingSoon}
            href={redirectUrl}
            target={target}
          >
            {isComingSoon ? 'Coming Soon' : 'Explore More'}
          </Button>
        </CardActions>
      </div>
      <CardMedia component="img" height="194" image={src} />
    </CardContent>
  </Card>
);

export default ExplareMoreCard;
