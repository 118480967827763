import React from 'react';
import {
  Stack,
  StackProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

export interface IBasicLoader {
  containerProps?: StackProps;
  loaderProps?: CircularProgressProps;
}
const BasicLoader: React.FC<IBasicLoader> = ({
  containerProps,
  loaderProps,
}) => {
  return (
    <Stack direction="row" justifyContent="center" {...containerProps}>
      <CircularProgress color="primary" {...loaderProps} />
    </Stack>
  );
};

export default BasicLoader;
