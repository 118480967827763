import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITrialClass } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';

export interface ICreateTrial {
  id?: string;
  userId: string;
  freeSlots?: number[];
  tutorId?: string;
  selectedTime?: number;
  subject?: string;
  curriculum?: string;
  type: 'Additional Trial' | 'New Lead';
  salesAgent?: string;
  groupClassId?: string;
  groupClassTopicId?: string;
  overRideStudentCountry?: string;
}

export interface IGetTrialClass {
  id: string;
}

export interface IUpdateTrialClassTopicAndSpecialRequirements {
  id: string;
  userId: string;
  topic: string;
  specialRequirements: string;
}

const TrialApiDataProvider = new APIDataProvider<ITrialClass>();

export const createTrialClass = createAsyncThunk(
  'trial/create',
  async (data: ICreateTrial) => {
    const response = await TrialApiDataProvider.create(ApiPaths.trial, data);

    return response.data;
  }
);

export const getTrialClass = createAsyncThunk(
  'trial/get',
  async (data: IGetTrialClass) => {
    const response = await TrialApiDataProvider.getById(
      `${ApiPaths.trial}/${data.id}`
    );

    return response.data;
  }
);

export const updateTrialTopicAndSpecialRequirements = createAsyncThunk(
  'trial/updateTopicAndSpecialRequirements',
  async (data: IUpdateTrialClassTopicAndSpecialRequirements) => {
    const response = await TrialApiDataProvider.create(
      `${ApiPaths.trial}/updateTopicAndSpecialRequirements`,
      data
    );

    return response.data;
  }
);

export default createTrialClass;
