import { PaletteOptions } from '@mui/material';

export const LightColorPaletteOptions: PaletteOptions = {
  mode: 'light',
  primary: {
    light: '#99E3DF',
    main: '#01B8AF',
    dark: '#004A46',
    contrastText: '#fff',
  },
  secondary: {
    light: '#D4D4D4',
    main: '#707070',
    dark: '#404040',
    contrastText: '#fff',
  },
  error: {
    light: '#FDEBEB',
    main: '#FF2B2D',
    dark: '#9E0001',
    contrastText: '#fff',
  },
  warning: {
    light: '#FEF8E8',
    main: '#F3C43F',
    dark: '#CBA436',
    contrastText: '#fff',
  },
  info: {
    light: '#E1EFFF',
    main: '#2D8CFF',
    dark: '#195195',
    contrastText: '#fff',
  },
  success: {
    light: '#EBF9EB',
    main: '#56C955',
    dark: '#2D6B2C',
    contrastText: '#fff',
  },
};

export const DarkColorPaletteOptions: PaletteOptions = {
  mode: 'dark',
  primary: {
    light: '#83DCD7',
    main: '#0CD6CD',
    dark: '#076A64',
    contrastText: '#000000',
  },
  secondary: {
    light: '#E6E6E6',
    main: '#EDEDED',
    dark: '#686868',
    contrastText: '#000000',
  },
  error: {
    light: '#E57373',
    main: '#F44336',
    dark: '#D32F2F',
    contrastText: '#000000',
  },
  warning: {
    light: '#FFB74D',
    main: '#FFA726',
    dark: '#F57C00',
    contrastText: '#000000',
  },
  info: {
    light: '#4FC3F7',
    main: '#29B6F6',
    dark: '#0288D1',
    contrastText: '#000000',
  },
  success: {
    light: '#81C784',
    main: '#66BB6A',
    dark: '#388E3C',
    contrastText: '#000000',
  },
};

export default LightColorPaletteOptions;
