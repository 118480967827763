import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
// ICONS
import SearchIcon from '@mui/icons-material/Search';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  getCurriculumState,
  ICurriculum,
} from '../../../store/CurriculumSlice';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

interface ICurriculumsModalProps {
  open: boolean;
  onClose: () => void;
  selectedCurriculum?: ICurriculum | undefined;
  onCurriculumChange: (curriculum: ICurriculum) => void;
}

const modalHeading = 'All Level';

const CurriculumsModal: React.FC<ICurriculumsModalProps> = ({
  open,
  onClose,
  selectedCurriculum,
  onCurriculumChange,
}) => {
  // HOOKS
  const curriculumState = useAppSelector(getCurriculumState);

  // LOCAL STATES
  const [searchText, setSearchText] = useState('');

  // METHODS
  const filterCurriculumList = (curriculum: ICurriculum) => {
    const text = searchText.trim();
    if (text) {
      return curriculum.curriculum
        .toLocaleLowerCase()
        .includes(text.toLocaleLowerCase());
    }
    const ignoredList = curriculumState.list
      ? curriculumState.list.slice(0, 11)
      : [];
    return !ignoredList.some((c) => c === curriculum);
  };

  const _curriculumList: ICurriculum[] = curriculumState.list
    ? curriculumState.list.filter(filterCurriculumList)
    : [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: {
            xs: '90%',
            sm: '400px',
          },
          maxWidth: '400px',
          borderRadius: '16px',
        },
      }}
    >
      <Box sx={{ backgroundColor: 'primary.main', pt: '25px', pb: '25px' }}>
        <Typography
          variant="h6"
          fontWeight={500}
          color={TP_COLORS.white}
          textAlign="center"
        >
          {modalHeading}
        </Typography>
      </Box>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                value={searchText}
                placeholder="Search Level"
                onChange={(event) => setSearchText(event.currentTarget.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box
          sx={{
            maxHeight: '50vh',
            minHeight: '50vh',
            overflowY: 'auto',
            margin: '15px -24px 0 -24px',
            padding: '0 15px 15px 15px',
          }}
        >
          <List>
            {_curriculumList.length > 0 ? (
              _curriculumList.map((curriculum, index) => {
                const _isSelected =
                  selectedCurriculum?.curriculum === curriculum.curriculum;
                return (
                  <ListItem key={index} disablePadding sx={{ mb: '5px' }}>
                    <ListItemButton
                      selected={_isSelected}
                      sx={{
                        p: '0 15px',
                        backgroundColor: _isSelected
                          ? undefined
                          : TP_COLORS.paperGray.light,
                        borderRadius: '4px',
                        '&:focus': {
                          backgroundColor: _isSelected
                            ? undefined
                            : TP_COLORS.paperGray.light,
                        },
                      }}
                      onClick={() => onCurriculumChange(curriculum)}
                    >
                      <ListItemText primary={curriculum.curriculum} />
                    </ListItemButton>
                  </ListItem>
                );
              })
            ) : (
              <Typography variant="h6" textAlign="center">
                No results found
              </Typography>
            )}
          </List>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CurriculumsModal;
