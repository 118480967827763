import React from 'react';
import { Card, CardContent, CardActionArea, Typography } from '@mui/material';
// HELPERS
import { getSubjectIcons } from '../../../helper';

export interface ISubjectCard {
  subject: string;
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const SubjectCard: React.FC<ISubjectCard> = ({
  subject,
  selected,
  onClick,
  disabled,
}) => {
  const SubjectIcon = getSubjectIcons(subject);

  let _borderColor: React.CSSProperties['color'] = 'transparent';
  if (selected) {
    _borderColor = '#01B8AF';
  }

  // RENDER
  return (
    <Card
      elevation={0}
      style={{
        border: `2px solid ${_borderColor}`,
      }}
    >
      <CardContent style={{ padding: '0' }}>
        <CardActionArea onClick={onClick} disabled={disabled}>
          <div
            style={{
              minHeight: '80px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <SubjectIcon
              style={{ width: '60px', height: '60px' }}
              fill="#01B8AF"
            />
          </div>
          <div>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              fontSize="12px"
              textAlign="center"
              sx={{ m: '5px 0' }}
              className="ellipsis-text two-lines"
            >
              {subject}
            </Typography>
          </div>
        </CardActionArea>
      </CardContent>
    </Card>
  );
};

export default SubjectCard;
