import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// HELPERS
import {
  removeAuthTokenFromLocalStorage,
  setAuthTokenToLocalStorage,
} from '../../helper';
// API
import { getClientCredentialsToken } from './AuthApi';

export interface iAuthState {
  status: 'idle' | 'loading' | 'rejected';
}

const initialState: iAuthState = {
  status: 'idle',
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      // AUTH
      .addCase(getClientCredentialsToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getClientCredentialsToken.fulfilled, (state, action) => {
        state.status = 'idle';
        setAuthTokenToLocalStorage(action.payload.access_token);
      })
      .addCase(getClientCredentialsToken.rejected, (state) => {
        state.status = 'rejected';
        removeAuthTokenFromLocalStorage();
      });
  },
});

export const getAuthState = (state: RootState): RootState['auth'] => state.auth;

export default AuthSlice.reducer;
