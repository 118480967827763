import React from 'react';
import { CircularProgress } from '@mui/material';

const containerStyles: React.CSSProperties = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const FullScreenLoader: React.FC = () => {
  return (
    <div style={containerStyles}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default FullScreenLoader;
