import { createAsyncThunk } from '@reduxjs/toolkit';
import { IOnboardingSubjectSettingsData } from '.';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';

export interface IGetOnboardingSubjectSettingsData {
  subject: string;
  curriculum: string;
  grade: string;
}

export interface IValidateUrlParams {
  curriculum?: string;
  grade?: string;
  subject?: string;
}

const SettingsDataProvider = new APIDataProvider<IOnboardingSubjectSettingsData>();
const ValidateUrlDataProvider = new APIDataProvider<{
  curriculum?: string;
  grade?: string;
  subject?: string;
}>();

export const getOnboardingSubjectSettingsData = createAsyncThunk(
  'settings/onboardingSubjectSettings',
  async (data: IGetOnboardingSubjectSettingsData) => {
    const response = await SettingsDataProvider.create(
      `${ApiPaths.settings}/onboardingSubjects`,
      data
    );

    return response.data;
  }
);

export const validateUrlParams = createAsyncThunk(
  'settings/validateUrlParams',
  async (data: IValidateUrlParams) => {
    const response = await ValidateUrlDataProvider.create(
      `${ApiPaths.settings}/validateUrlParams`,
      data
    );

    return response.data;
  }
);

export default getOnboardingSubjectSettingsData;
