import React from 'react';
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

export interface ISubjectLevelCard {
  curriculum: string;
  onClick?: () => void;
  selected: boolean;
  disabled?: boolean;
}

const SubjectLevelCard: React.FC<ISubjectLevelCard> = ({
  curriculum,
  onClick,
  selected,
  disabled,
}) => {
  let _bgColor = '#e5f8f7';
  let _textColor = 'primary';

  if (selected) {
    _bgColor = 'primary.main';
    _textColor = TP_COLORS.white;
  }

  // RENDER
  return (
    <Tooltip title={curriculum} placement="top">
      <Card sx={{ backgroundColor: _bgColor }}>
        <CardContent style={{ padding: '0' }}>
          <CardActionArea onClick={onClick} disabled={disabled}>
            <Box
              style={{
                minHeight: '80px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                className="ellipsis-text two-lines"
                variant="subtitle2"
                fontWeight={600}
                color={_textColor}
                fontSize="12px"
              >
                {curriculum}
              </Typography>
            </Box>
          </CardActionArea>
        </CardContent>
      </Card>
    </Tooltip>
  );
};

export default SubjectLevelCard;
