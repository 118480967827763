import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
import { ISubject } from '../CurriculumSlice';
// API
import { getSubjects } from './SubjectApi';

export interface ISubjectState {
  status: 'idle' | 'loading' | 'rejected';
  list: Array<ISubject>;
}

const initialState: ISubjectState = {
  status: 'idle',
  list: [],
};

export const SubjectSlice = createSlice({
  name: 'subject',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getSubjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSubjects.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getSubjects.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getSubjectState = (state: RootState): RootState['subject'] =>
  state.subject;

export * from './SubjectApi';
export default SubjectSlice.reducer;
