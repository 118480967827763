import { PaletteOptions } from '@mui/material';

export type TTheme = PaletteOptions['mode'];

// GET FROM LOCAL STORAGE
export const getAppThemeModeFromLocalStorage = (): TTheme | undefined => {
  const data = localStorage.getItem('TUTOPIYA_ONBOARDING_APP_THEME_MODE');
  const mode: TTheme = (data as TTheme) || 'light';
  return mode.trim() as TTheme;
};
export const getAuthTokenFromLocalStorage = (): string | undefined => {
  const data = localStorage.getItem('TUTOPIYA_ONBOARDING_AUTH_TOKEN');
  if (data) {
    return data;
  }
  return undefined;
};

export const getTutopiyaPlatformTokenFromLocalStorage = ():
  | string
  | undefined => {
  const data = localStorage.getItem('TUTOPIYA_PLATFORM_TOKEN');
  if (data) {
    return data;
  }
  return undefined;
};

export const removeTutopiyaPlatformTokenFromLocalStorage = (): void => {
  localStorage.removeItem('TUTOPIYA_PLATFORM_TOKEN');
};

// REMOVE FROM LOCAL STORAGE
export const removeAuthTokenFromLocalStorage = (): void => {
  localStorage.removeItem('TUTOPIYA_ONBOARDING_AUTH_TOKEN');
};

// SET TO LOCAL STORAGE
export const setAppThemeModeToLocalStorage = (mode: TTheme): void => {
  localStorage.setItem('TUTOPIYA_ONBOARDING_APP_THEME_MODE', mode as string);
};

export const setAuthTokenToLocalStorage = (token: string): void => {
  localStorage.setItem('TUTOPIYA_ONBOARDING_AUTH_TOKEN', token);
};

export const setTutopiyaPlatformTokenToLocalStorage = (token: string): void => {
  localStorage.setItem('TUTOPIYA_PLATFORM_TOKEN', token);
};
