import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import { getSelectedFreeSlots } from './FreeSlotSelectApi';

export interface IFreeSlot {
  start: string;
  end: string;
  userId?: Array<string>;
  groupClassId?: string;
  groupClassTopicId?: string;
}

export interface IFreeSlotList {
  slots: Array<IFreeSlot>;
  type: string;
  rate: ICountryRate | undefined;
}

export interface ICountryRate {
  country: string;
  currency: string;
  // eslint-disable-next-line camelcase
  unlimited_rate: number;
  // eslint-disable-next-line camelcase
  one_to_one_rate: number;
}
export interface IFreeSlotState {
  status: 'idle' | 'loading' | 'rejected';
  // eslint-disable-next-line @typescript-eslint/ban-types
  list: IFreeSlotList;
}

const initialState: IFreeSlotState = {
  status: 'idle',
  list: {
    slots: [],
    type: '',
    rate: undefined,
  },
};

export const FreeSlotSelectSlice = createSlice({
  name: 'freeSlotSelect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getSelectedFreeSlots.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSelectedFreeSlots.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getSelectedFreeSlots.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getFreeSlotSelectState = (
  state: RootState
): RootState['freeSlotSelect'] => state.freeSlotSelect;

export * from './FreeSlotSelectApi';
export default FreeSlotSelectSlice.reducer;
