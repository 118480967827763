import { ReactComponent as CommonIcon } from './icons/Common.svg';
import { ReactComponent as AccountingIcon } from './icons/Accounting.svg';
import { ReactComponent as ArabicIcon } from './icons/Arabic.svg';
import { ReactComponent as BiologyIcon } from './icons/Biology.svg';
import { ReactComponent as BusinessManagementIcon } from './icons/Business Managment.svg';
import { ReactComponent as BusinessStudiesIcon } from './icons/Business Studies.svg';
import { ReactComponent as ChemistryIcon } from './icons/Chemistry.svg';
import { ReactComponent as ChineseIcon } from './icons/Chinese.svg';
import { ReactComponent as CoordinatedScienceIcon } from './icons/Co-ordinated Science.svg';
import { ReactComponent as CombinedScienceIcon } from './icons/Combined Science.svg';
import { ReactComponent as CommerceIcon } from './icons/Commerce.svg';
import { ReactComponent as ComputerScienceIcon } from './icons/Computer Science.svg';
import { ReactComponent as ComputingIcon } from './icons/Computing.svg';
import { ReactComponent as EconomicsIcon } from './icons/Economics.svg';
import { ReactComponent as EnglishIcon } from './icons/English.svg';
import { ReactComponent as FrenchIcon } from './icons/French.svg';
import { ReactComponent as GeographyIcon } from './icons/Geography.svg';
import { ReactComponent as HindiIcon } from './icons/Hindi.svg';
import { ReactComponent as HistoryIcon } from './icons/History.svg';
import { ReactComponent as HumanBiologyIcon } from './icons/Human biology.svg';
import { ReactComponent as ITIcon } from './icons/IT.svg';
import { ReactComponent as MalayIcon } from './icons/Malay.svg';
import { ReactComponent as MathsIcon } from './icons/Maths.svg';
import { ReactComponent as PhysicsIcon } from './icons/Physics.svg';
import { ReactComponent as SpanishIcon } from './icons/Spanish.svg';
import { ReactComponent as TamilIcon } from './icons/Tamil.svg';

export const getSubjectIcons = (
  subject: string
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> => {
  let icon = CommonIcon;
  switch (subject) {
    case 'Accounting':
      icon = AccountingIcon;
      break;

    case 'Arabic':
      icon = ArabicIcon;
      break;

    case 'Biology':
      icon = BiologyIcon;
      break;

    case 'Business Managment':
      icon = BusinessManagementIcon;
      break;

    case 'Business Studies':
      icon = BusinessStudiesIcon;
      break;

    case 'Chemistry':
      icon = ChemistryIcon;
      break;

    case 'Chinese':
      icon = ChineseIcon;
      break;

    case 'Co-ordinated Science':
      icon = CoordinatedScienceIcon;
      break;

    case 'Combined Science':
      icon = CombinedScienceIcon;
      break;

    case 'Commerce':
      icon = CommerceIcon;
      break;

    case 'Computer Science':
      icon = ComputerScienceIcon;
      break;

    case 'Computing':
      icon = ComputingIcon;
      break;

    case 'Economics':
      icon = EconomicsIcon;
      break;

    case 'English':
    case 'English Language':
      icon = EnglishIcon;
      break;

    case 'French':
      icon = FrenchIcon;
      break;

    case 'Geography':
      icon = GeographyIcon;
      break;

    case 'Hindi':
      icon = HindiIcon;
      break;

    case 'History':
      icon = HistoryIcon;
      break;

    case 'Human biology':
      icon = HumanBiologyIcon;
      break;

    case 'IT':
      icon = ITIcon;
      break;

    case 'Malay':
      icon = MalayIcon;
      break;

    case 'Maths':
    case 'Mathematics':
    case 'Additional Mathematics':
      icon = MathsIcon;
      break;

    case 'Physics':
      icon = PhysicsIcon;
      break;

    case 'Spanish':
      icon = SpanishIcon;
      break;

    case 'Tamil':
      icon = TamilIcon;
      break;

    default:
      icon = CommonIcon;
      break;
  }
  return icon;
};

export default getSubjectIcons;
