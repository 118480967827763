import React, { useEffect, useState } from 'react';
import {
  Box,
  BoxProps,
  Stack,
  Typography,
  Grid,
  Button,
  StackProps,
  Card,
  CardContent,
  Container,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';
import { isMobile, SafeAreaGaps } from '../../helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getTrialClass, getTrialState } from '../../store/TrialSlice';
import { getTrialUserById } from '../../store/TrialUserSlice';
import { createAnalytics } from '../../store/AnalyticsSlice';

interface Tutor {
  firstName: string;
  lastName: string;
  image: string;
  gender: string;
  experience: string;
  qualification: string;
  languages: Array<{ language: string; flag: string }>;
}

// STYLES
const contentContainerHeight = isMobile ? '100vh' : 'calc(100vh - 184.97px)';

const containerStyles: BoxProps['sx'] = {
  minHeight: '100vh',
  overflowY: 'auto',
  background: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: `url('/images/backgrounds/bg-lg.png') center/101% 100%`,
  },
  p: {
    xs: '1px',
    lg: 'unset',
  },
};

const contentContainerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  minHeight: contentContainerHeight,
};

const subContentContainerStyles: BoxProps['sx'] = {
  display: 'flex',
  p: { xs: '15px', lg: '15px 100px' },
  justifyContent: 'center',
  mb: '120px',
};

const buttonContainerStyles: StackProps['sx'] = {
  position: 'fixed',
  boxShadow: '0px -16px 40px 0px #6E9DB22E',
  left: 0,
  bottom: SafeAreaGaps.bottom,
  backgroundColor: 'background.default',
  width: '100%',
  p: '10px 0',
};

const mobileImageContainerStyles: StackProps['sx'] = {
  backgroundColor: 'background.default',
  position: 'fixed',
  top: SafeAreaGaps.top,
  width: '100%',
  left: 0,
  p: '15px 0',
  display: { xs: 'flex', lg: 'none' },
  zIndex: 1,
};

const SelectTutor: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  // STORE
  const trialState = useAppSelector(getTrialState);

  const _trialClass = trialState.trialClass;
  const params = new URLSearchParams(location.search);
  const trialId = params.get('id');

  // STATE
  const [selectedTutor, setSelectedTutor] = useState<Tutor | null>(null);

  // USE EFFECT
  useEffect(() => {
    // @ts-ignore
    fbq('track', 'NewSignup');

    if (trialId) {
      dispatch(
        getTrialClass({
          id: trialId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (_trialClass && _trialClass.studentId) {
      dispatch(getTrialUserById(_trialClass.studentId));
    }
  }, [_trialClass]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trialClass: any = _trialClass;

  const handleTutorSelect = (tutor: Tutor) => {
    if (
      selectedTutor?.firstName === tutor.firstName &&
      selectedTutor?.lastName === tutor.lastName
    ) {
      setSelectedTutor(null);
    } else {
      setSelectedTutor(tutor);
    }
  };

  const handleContinue = () => {
    if (selectedTutor) {
      dispatch(
        createAnalytics({
          type: 'Onboarding - Tutor Selection',
          data: {
            selectedTutorFirstName: selectedTutor.firstName,
            selectedTutorLastName: selectedTutor.lastName,
            trialStudentEmail: trialClass?.student?.email,
            trialStudentId: trialClass?.studentId,
            trialClassId: trialClass?.id,
            trialBookedById: trialClass?.bookedBy?.id,
            trialBookedByRole: trialClass?.bookedBy?.role,
          },
          userId: trialClass?.studentId,
        })
      );
    }
    window.location.href = `${process.env.REACT_APP_BASE_URL}/onboarding/complete/trial-class-details?id=${trialId}`;
  };

  const tutors: Tutor[] = [
    {
      firstName: 'Tharushi',
      lastName: 'J',
      image: '/images/selectTutors/avatar1.png',
      gender: 'Female tutor',
      experience: '8+ years of experience',
      qualification: 'PhD from University of Colombo',
      languages: [
        {
          language: 'English UK/US',
          flag: '/images/selectTutors/english.png',
        },
        {
          language: 'Sinhalese',
          flag: '/images/selectTutors/sinhala.png',
        },
      ],
    },
    {
      firstName: 'Anjal',
      lastName: 'P',
      image: '/images/selectTutors/avatar2.png',
      gender: 'Male tutor',
      experience: '10+ years of experience',
      qualification: 'Master of Science from University of Madras',
      languages: [
        {
          language: 'English UK/US',
          flag: '/images/selectTutors/english.png',
        },
        {
          language: 'Hindi',
          flag: '/images/selectTutors/hindi.png',
        },
      ],
    },
    {
      firstName: 'James',
      lastName: 'G',
      image: '/images/selectTutors/avatar3.png',
      gender: 'Male tutor',
      experience: '6+ years of experience',
      qualification: 'Bachelor from Monash University',
      languages: [
        {
          language: 'English UK/US',
          flag: '/images/selectTutors/english.png',
        },
      ],
    },
    {
      firstName: 'Humera',
      lastName: 'G',
      image: '/images/selectTutors/avatar4.png',
      gender: 'Female tutor',
      experience: '5+ years of experience',
      qualification: 'Master of Science from Northwood University',
      languages: [
        {
          language: 'English UK/US',
          flag: '/images/selectTutors/english.png',
        },
      ],
    },
  ];

  // RENDER
  return (
    <Box sx={containerStyles}>
      <Stack
        direction="row"
        justifyContent="center"
        sx={mobileImageContainerStyles}
      >
        <img
          src="/images/logos/tp-logo-green.png"
          alt="tp-logo"
          width="120px"
        />
      </Stack>
      <Box sx={{ minHeight: '85px', display: { xs: 'block', lg: 'none' } }} />
      {!isMobile && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <img
            src="/images/logos/tp-logo-text-white.png"
            alt="tp-logo"
            style={{ width: '150px', marginTop: '20px' }}
          />
          <img
            src="/images/shapes/wave.png"
            alt="wave-shape"
            style={{ width: '100%', marginTop: '20px', marginBottom: '-2px' }}
          />
        </Stack>
      )}
      <Box sx={contentContainerStyles}>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '50px',
          }}
        >
          <Typography variant="h5" fontWeight={600}>
            Select Your Tutor
          </Typography>
          <Typography color="secondary" variant="subtitle2" paddingTop="10px">
            Choose your tutor and get ready for a learning experience that makes
            a lasting impact
          </Typography>
        </div>
        {/* Cards */}
        <Box sx={subContentContainerStyles}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              {tutors.map((tutor, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    onClick={() => handleTutorSelect(tutor)}
                    className="select-tutor"
                    sx={{
                      borderRadius: 4,
                      cursor: 'pointer',
                      border:
                        selectedTutor?.firstName === tutor.firstName
                          ? '2px solid #01A79F'
                          : 'none',
                      position: 'relative',
                    }}
                    elevation={0}
                  >
                    {selectedTutor?.firstName === tutor.firstName && (
                      <CheckCircleIcon
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          color: '#01A79F',
                          fontSize: 24,
                        }}
                      />
                    )}
                    <CardContent
                      sx={{
                        padding: 2,
                        backgroundColor: '#F1F5F9',
                        height: '230px',
                      }}
                    >
                      <Stack direction="row" gap={3}>
                        <img
                          src={tutor.image}
                          alt="avatar1"
                          style={{ width: 40, height: 40 }}
                        />
                        <Box>
                          <Typography fontSize="17px" fontWeight="bold">
                            {tutor.firstName} {tutor.lastName}
                          </Typography>
                          <Typography
                            fontSize="13px"
                            fontWeight="normal"
                            color="textSecondary"
                          >
                            {tutor.gender}
                          </Typography>
                        </Box>
                      </Stack>
                      <Box sx={{ marginTop: '20px' }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          marginBottom="5px"
                        >
                          Experience & Qualification
                        </Typography>
                        <Typography variant="body2">
                          • {tutor.experience}
                        </Typography>
                        <Typography variant="body2">
                          • {tutor.qualification}
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: '20px' }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          marginBottom="10px"
                        >
                          Speaks
                        </Typography>
                        <Stack direction="row" margin="2px" gap={1}>
                          {tutor.languages.map((language, i) => (
                            <Box
                              sx={{
                                padding: '5px',
                                backgroundColor: '#E2E8F0',
                                borderRadius: '25px',
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                margin="2px"
                                spacing={1}
                                key={i}
                              >
                                <img
                                  src={language.flag}
                                  alt={`${language.language} flag`}
                                  style={{ width: 20, height: 15 }}
                                />
                                <Typography fontSize="12px">
                                  {language.language}
                                </Typography>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        {/* BUTTON CONTAINER */}
        {!isMobile && (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={buttonContainerStyles}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              style={{ maxWidth: '300px' }}
              onClick={handleContinue}
            >
              Continue
            </Button>
            <Typography
              fontWeight={600}
              color="secondary"
              variant="subtitle2"
              style={{ padding: '10px' }}
            >
              Please login here to check your class details. Check your email
              for more information
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default SelectTutor;
