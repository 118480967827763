import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { IUser } from '../SignupSlice';
import { getTrialUserById, updateTrialUser } from './TrialUserApi';

export interface ITrialUserState {
  status: 'idle' | 'loading' | 'rejected';
  user: IUser | undefined;
  subject: string | undefined;
  hasCurriculum: boolean;
}

const initialState: ITrialUserState = {
  status: 'idle',
  user: undefined,
  subject: undefined,
  hasCurriculum: true,
};

const TrialUserSlice = createSlice({
  name: 'trialUser',
  initialState,
  reducers: {
    updateSubject(state, action: PayloadAction<string>) {
      if (state.user) {
        state.subject = action.payload;
      }
    },
    updateCurriculum(state, action: PayloadAction<string>) {
      if (state.user) {
        state.user.curriculum = action.payload;
      }
    },
    setHadCurriculum(state, action: PayloadAction<boolean>) {
      state.hasCurriculum = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrialUserById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTrialUserById.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getTrialUserById.rejected, (state) => {
        state.status = 'rejected';
      })
      .addCase(updateTrialUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTrialUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(updateTrialUser.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getTrialUserState = (state: RootState): RootState['trialUser'] => {
  return state.trialUser;
};

export const {
  updateSubject,
  updateCurriculum,
  setHadCurriculum,
} = TrialUserSlice.actions;

export * from './TrialUserApi';
export default TrialUserSlice.reducer;
