import { createSlice } from '@reduxjs/toolkit';
import { PaletteOptions } from '@mui/material';
import { RootState } from '../index';
// HELPERS
import {
  setAppThemeModeToLocalStorage,
  getAppThemeModeFromLocalStorage,
} from '../../helper/LocalStorage';

export type TTheme = PaletteOptions['mode'];

export interface IConfigState {
  themeMode: TTheme;
  status: 'idle' | 'loading';
}

const initialState: IConfigState = {
  themeMode: getAppThemeModeFromLocalStorage(),
  status: 'idle',
};

export const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const newTheme: TTheme = state.themeMode === 'light' ? 'dark' : 'light';
      state.themeMode = newTheme;
      setAppThemeModeToLocalStorage(newTheme);
    },
  },
});

export const { toggleTheme } = ConfigSlice.actions;

export const getThemeMode = (state: RootState): TTheme =>
  state.config.themeMode;

export default ConfigSlice.reducer;
