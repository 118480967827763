import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import {
  Box,
  BoxProps,
  Stack,
  Typography,
  Grid,
  Button,
  StackProps,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import Moment from 'moment-timezone';
import DeleteIcon from '@mui/icons-material/Delete';

import { TP_COLORS } from '../../constants';
import {
  getTrialClass,
  getTrialState,
  updateTrialTopicAndSpecialRequirements,
} from '../../store/TrialSlice';
import {
  getTrialUserById,
  getTrialUserState,
} from '../../store/TrialUserSlice';
import { getThemeMode } from '../../store/ConfigSlice';
import { useAppSelector } from '../../hooks';
import {
  getTutopiyaPlatformTokenFromLocalStorage,
  isMobile,
  SafeAreaGaps,
} from '../../helper';
import DetailViewCard from '../../components/Card/DetailViewCard';
import TrialClassCreationLoader from '../../components/Loaders/TrialClassCreationLoader';
import AddTopicAndSpecialRequirementsModal from './modals/AddTopicAndSpecialRequirementsModal';

// STYLES
const contentContainerHeight = isMobile ? '100vh' : 'calc(100vh - 184.97px)';

const buttonContainerHeight = '56.5px';

const containerStyles: BoxProps['sx'] = {
  minHeight: '100vh',
  overflowY: 'auto',
  background: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: `url('/images/backgrounds/bg-lg.png') center/101% 100%`,
  },
  p: {
    xs: '1px',
    lg: 'unset',
  },
};

const contentContainerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  minHeight: contentContainerHeight,
};

const subContentContainerStyles: BoxProps['sx'] = {
  display: 'flex',
  p: { xs: '15px', lg: '15px 100px' },
  justifyContent: 'center',
  mb: '100px',
};

const buttonContainerStyles: StackProps['sx'] = {
  position: 'fixed',
  boxShadow: '0px -16px 40px 0px #6E9DB22E',
  left: 0,
  bottom: SafeAreaGaps.bottom,
  backgroundColor: 'background.default',
  width: '100%',
  p: '10px 0',
};

const mobileImageContainerStyles: StackProps['sx'] = {
  backgroundColor: 'background.default',
  position: 'fixed',
  top: SafeAreaGaps.top,
  width: '100%',
  left: 0,
  p: '15px 0',
  display: { xs: 'flex', lg: 'none' },
  zIndex: 1,
};

const buttonGapStyles: BoxProps['sx'] = {
  display: { xs: 'block', sm: 'none' },
  minHeight: buttonContainerHeight,
};

const CompleteSteps: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // STORE
  const trialState = useAppSelector(getTrialState);
  const trialUserState = useAppSelector(getTrialUserState);
  const themeMode = useAppSelector(getThemeMode);

  // STATE
  const [state, setState] = useState({
    isAddTopicAndSpecialReqModalOpen: false,
    initialLoading: true,
  });

  const trialUser = trialUserState.user;
  const _trialClass = trialState.trialClass;
  const platformSignInToken = getTutopiyaPlatformTokenFromLocalStorage();
  const params = new URLSearchParams(location.search);
  const trialId = params.get('id');
  const topicFieldEnabled = useFlag(
    'onboarding_topic_request_topic_field_enabled'
  );

  // STYLES
  const cardContainerStyles: BoxProps['sx'] = {
    p: '12px',
    mb: '10px',
    borderRadius: '7px',
    background:
      themeMode === 'light'
        ? TP_COLORS.listItemGray.light
        : TP_COLORS.listItemGray.dark,
  };

  // USE EFFECT
  useEffect(() => {
    // @ts-ignore
    fbq('track', 'NewSignup');

    if (trialId) {
      dispatch(
        getTrialClass({
          id: trialId,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (_trialClass && _trialClass.studentId) {
      dispatch(getTrialUserById(_trialClass.studentId));
    }
  }, [_trialClass]);

  useEffect(() => {
    if (_trialClass && trialUser) {
      setState((prevState) => ({
        ...prevState,
        initialLoading: false,
      }));
    }
  }, [_trialClass, trialUser]);

  // METHODS
  const getShortName = (
    firstName: string | undefined,
    lastName: string | undefined
  ) => {
    const fname = firstName || '';
    const lname = lastName ? lastName.substring(0, 1) : '';
    return `${fname} ${lname}.`;
  };
  const toggleAddTopicAndSpecialReqModal = () => {
    setState((prevState) => ({
      ...prevState,
      isAddTopicAndSpecialReqModalOpen: !prevState.isAddTopicAndSpecialReqModalOpen,
    }));
  };

  const handleDeleteTopic = () => {
    if (_trialClass) {
      // eslint-disable-next-line
      const confirmed = window.confirm('Are you sure you want to delete this?');
      if (confirmed) {
        dispatch(
          updateTrialTopicAndSpecialRequirements({
            id: _trialClass.id,
            userId: _trialClass.studentId,
            topic: '',
            specialRequirements: _trialClass.specialRequirements || '',
          })
        );
      }
    }
  };

  const handleDeleteSpecialRequirements = () => {
    if (_trialClass) {
      // eslint-disable-next-line
      const confirmed = window.confirm('Are you sure you want to delete this?');
      if (confirmed) {
        dispatch(
          updateTrialTopicAndSpecialRequirements({
            id: _trialClass.id,
            userId: _trialClass.studentId,
            topic: _trialClass.topic || '',
            specialRequirements: '',
          })
        );
      }
    }
  };

  // DATA
  let timeSlots = '';
  let dateTime = '';

  if (_trialClass) {
    [...(_trialClass.freeSlots || [])].forEach((timestamp) => {
      if (trialUser && trialUser.country) {
        timeSlots += `${Moment(timestamp)
          .tz(trialUser.country)
          .format('DD MMM YYYY (ddd) hh:mm A')}\n`;
      } else {
        timeSlots += `${moment(timestamp).format(
          'DD MMM YYYY (ddd) hh:mm A'
        )}\n`;
      }
    });

    if (_trialClass.selectedTime) {
      dateTime = moment(_trialClass.selectedTime).format(
        'DD MMMM YYYY (dddd) h:mm:ss a'
      );

      if (trialUser && trialUser.country) {
        dateTime = Moment(_trialClass.selectedTime)
          .tz(trialUser.country)
          .format('DD MMMM YYYY (dddd) h:mm:ss a');
      }
    }
  }

  if (state.initialLoading) {
    return <TrialClassCreationLoader />;
  }

  const tutorName = getShortName(
    _trialClass?.tutor?.firstName,
    _trialClass?.tutor?.lastName
  );

  // RENDER
  return (
    <Box sx={containerStyles}>
      <Stack
        direction="row"
        justifyContent="center"
        sx={mobileImageContainerStyles}
      >
        <img
          src="/images/logos/tp-logo-green.png"
          alt="tp-logo"
          width="120px"
        />
      </Stack>
      <Box sx={{ minHeight: '85px', display: { xs: 'block', lg: 'none' } }} />
      {!isMobile && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <img
            src="/images/logos/tp-logo-text-white.png"
            alt="tp-logo"
            style={{ width: '150px', marginTop: '20px' }}
          />
          <img
            src="/images/shapes/wave.png"
            alt="wave-shape"
            style={{ width: '100%', marginTop: '20px', marginBottom: '-2px' }}
          />
        </Stack>
      )}
      <Box sx={contentContainerStyles}>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <Typography variant="h5" fontWeight={600}>
            You&apos;re all set
          </Typography>
          <Typography
            fontWeight={600}
            color="secondary"
            variant="subtitle2"
            paddingTop="10px"
          >
            Please add your special requirements if any
          </Typography>
        </div>
        <Box sx={subContentContainerStyles}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ maxWidth: '600px' }}
          >
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  color="inherit"
                  style={{
                    color: TP_COLORS.black,
                    background: 'unset',
                    border: '1px solid #E2E8F0',
                    boxShadow: 'none',
                  }}
                  onClick={toggleAddTopicAndSpecialReqModal}
                >
                  {topicFieldEnabled
                    ? 'Add Topic & Special Requirements'
                    : 'Add Special Requirements'}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                title="Level"
                description={_trialClass ? _trialClass.curriculum : '-'}
                titleProps={{ variant: 'caption' }}
                descriptionProps={{ variant: 'subtitle1', fontWeight: 600 }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                title="Subject"
                description={_trialClass ? _trialClass.subject : '-'}
                titleProps={{ variant: 'caption' }}
                descriptionProps={{ variant: 'subtitle1', fontWeight: 600 }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                title="Grade"
                description={_trialClass ? _trialClass.grade : '-'}
                titleProps={{ variant: 'caption' }}
                descriptionProps={{ variant: 'subtitle1', fontWeight: 600 }}
              />
            </Grid>
            {trialUser && trialUser.country ? (
              <Grid item xs={12}>
                <DetailViewCard
                  title="Country"
                  description={trialUser.country}
                  titleProps={{ variant: 'caption' }}
                  descriptionProps={{ variant: 'subtitle1', fontWeight: 600 }}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <DetailViewCard
                title="Time Slots"
                description={timeSlots || '-'}
                titleProps={{ variant: 'caption' }}
                descriptionProps={{
                  variant: 'subtitle1',
                  fontWeight: 600,
                  whiteSpace: 'pre',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DetailViewCard
                title="Date & Time"
                description={dateTime || '-'}
                titleProps={{ variant: 'caption' }}
                descriptionProps={{
                  variant: 'subtitle1',
                  fontWeight: 600,
                }}
              />
            </Grid>
            {_trialClass && _trialClass.tutor && (
              <Grid item xs={12}>
                <DetailViewCard
                  title="Tutor"
                  description={tutorName}
                  titleProps={{ variant: 'caption' }}
                  descriptionProps={{
                    variant: 'subtitle1',
                    fontWeight: 600,
                  }}
                  avatarProps={{ src: _trialClass.tutor.avatar }}
                />
              </Grid>
            )}
            {_trialClass && _trialClass.topic && (
              <Grid item xs={12}>
                <Box sx={cardContainerStyles}>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <Typography variant="caption">Topic</Typography>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {_trialClass.topic}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack alignItems="flex-end">
                        <IconButton color="error" onClick={handleDeleteTopic}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}

            {_trialClass && _trialClass.specialRequirements && (
              <Grid item xs={12}>
                <Box sx={cardContainerStyles}>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <Typography variant="caption">
                        Special Requirements
                      </Typography>
                      <Typography variant="subtitle1" fontWeight={600}>
                        {_trialClass.specialRequirements}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Stack alignItems="flex-end">
                        <IconButton
                          color="error"
                          onClick={handleDeleteSpecialRequirements}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* GAP */}
        <Box sx={buttonGapStyles} />
        {/* BUTTON CONTAINER */}
        {!isMobile && (
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={buttonContainerStyles}
          >
            <Button
              component="a"
              fullWidth
              variant="contained"
              color="primary"
              style={{ maxWidth: '300px' }}
              href={
                platformSignInToken
                  ? `${process.env.REACT_APP_TUTOPIYA_PLATFORM_BASE_URL}/#/jwtLogin?id_token=${platformSignInToken}`
                  : process.env.REACT_APP_TUTOPIYA_PLATFORM_BASE_URL
              }
            >
              Login
            </Button>
            <Typography
              fontWeight={600}
              color="secondary"
              variant="subtitle2"
              style={{ padding: '10px' }}
            >
              Please login here to check your class details. Check your email
              for more information
            </Typography>
          </Stack>
        )}
      </Box>

      {/* MODALS */}
      {state.isAddTopicAndSpecialReqModalOpen && (
        <AddTopicAndSpecialRequirementsModal
          open={state.isAddTopicAndSpecialReqModalOpen}
          onClose={toggleAddTopicAndSpecialReqModal}
        />
      )}
    </Box>
  );
};

export default CompleteSteps;
