/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  Box,
  BoxProps,
  Autocomplete,
  CircularProgress,
  Select,
  SelectChangeEvent,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Moment as IMoment } from 'moment';

import { Countries } from '../../../constants/Countries';
import { getFindUsOptions, IFindUsOption } from '../../../store/FindUsSlice';
import {
  getSignupState,
  ISignupUser,
  signupUser,
} from '../../../store/SignupSlice';
import {
  getTrialUserState,
  updateTrialUser,
} from '../../../store/TrialUserSlice';
import { getGrades, getGradeState } from '../../../store/GradeSlice';
import { getOnboardingSubjectSettingsData } from '../../../store/settings';
import { useAppSelector } from '../../../hooks';

type TGender = 'Male' | 'Female';

interface Step4Props {
  onTabChange: () => void;
  pageType: string;
}

interface ISelectOption {
  label: string;
  value: string;
}

const containerStyles: React.CSSProperties = {
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const buttonStyles: BoxProps['sx'] = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0',
  backgroundColor: 'background.default',
  position: 'absolute',
  bottom: '0',
  right: '0',
  width: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '40%',
  },
};

const defaultFindUsOption: IFindUsOption = {
  key: 'select',
  title: 'Select',
};

const defaultSelectOption: ISelectOption = {
  label: 'Select',
  value: 'Select',
};

const defaultErrorData = {
  visible: false,
  message: '',
};

const isEmailValid = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.toLocaleLowerCase().match(regex);
};

const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    let separatedCookie = cookies[i];
    while (separatedCookie.charAt(0) === ' ') {
      separatedCookie = separatedCookie.substring(1);
    }
    if (separatedCookie.indexOf(name) === 0) {
      return separatedCookie.substring(name.length, separatedCookie.length);
    }
  }
  return '';
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  ? Intl.DateTimeFormat().resolvedOptions().timeZone
  : 'Asia/Singapore';

const defaultUserTimezone = Countries.find(
  (country) => country.value === timezone
);

const Step4: React.FC<Step4Props> = ({ onTabChange, pageType }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', 'user-details');
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);

  // Store
  const signupState = useAppSelector(getSignupState);
  const gradeState = useAppSelector(getGradeState);
  const trialUserState = useAppSelector(getTrialUserState);

  // State
  const [trialUserCurriculum, setTrialUserCurriculum] = useState('');
  const [trialUserSubject, setTrialUserSubject] = useState('');
  const [state, setState] = useState({
    name: '',
    email: '',
    password: '',
    gender: 'Male' as TGender,
    date: null as IMoment | null,
    grade: { ...defaultSelectOption },
    findUs: { ...defaultFindUsOption },
    urlTracking: {
      utm_source: getCookie('utm_source') || null,
      utm_medium: getCookie('utm_medium') || null,
      utm_campaign: getCookie('utm_campaign') || null,
      utm_adgroup: getCookie('utm_adgroup') || null,
      utm_term: getCookie('utm_term') || null,
      utm_content: getCookie('utm_content') || null,
      lastUpdated: getCookie('lastUpdated') || null,
    },
    salesAgent: '',
    country:
      defaultUserTimezone && defaultUserTimezone.value
        ? defaultUserTimezone.value
        : 'Asia/Singapore',
  });

  const [errors, setErrors] = useState({
    name: { ...defaultErrorData },
    email: { ...defaultErrorData },
    password: { ...defaultErrorData },
  });

  const signUpUser = signupState.user;
  const trialUser = trialUserState.user;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newState = { ...state };

    dispatch(getFindUsOptions());

    if (
      signUpUser &&
      signUpUser.curriculum &&
      signUpUser.subject &&
      pageType === 'onboarding'
    ) {
      dispatch(
        getGrades({
          curriculum: signUpUser.curriculum,
          subject: signUpUser.subject,
        })
      );
    }

    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_adgroup = params.get('utm_adgroup');
    const utm_term = params.get('utm_term');
    const utm_content = params.get('utm_content');
    const lastUpdated = params.get('lastUpdated');
    const salesAgent = params.get('agentId');
    const email = params.get('email');

    const newUrlTracking = state.urlTracking;

    if (utm_source) {
      newUrlTracking.utm_source = utm_source;
    }

    if (utm_medium) {
      newUrlTracking.utm_medium = utm_medium;
    }

    if (utm_campaign) {
      newUrlTracking.utm_campaign = utm_campaign;
    }

    if (utm_adgroup) {
      newUrlTracking.utm_adgroup = utm_adgroup;
    }

    if (utm_term) {
      newUrlTracking.utm_term = utm_term;
    }

    if (utm_content) {
      newUrlTracking.utm_content = utm_content;
    }

    if (lastUpdated) {
      newUrlTracking.lastUpdated = lastUpdated;
    }

    newState.urlTracking = newUrlTracking;

    if (email) {
      newState.email = email;
    }

    if (salesAgent) {
      newState.salesAgent = salesAgent;
    }

    if (signUpUser && signUpUser.grade) {
      newState.grade = {
        label: signUpUser.grade,
        value: signUpUser.grade,
      };
    }

    setState(newState);
  }, []);

  useEffect(() => {
    if (pageType === 'trial') {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userId');

      if (
        trialUser &&
        userId &&
        userId === trialUser.id &&
        !trialUserState.hasCurriculum &&
        !trialUser.grade &&
        trialUserState.subject
      ) {
        setTrialUserCurriculum(trialUser.curriculum);
        setTrialUserSubject(trialUser.subject);
        dispatch(
          getGrades({
            curriculum: trialUser.curriculum,
            subject: trialUserState.subject,
          })
        );
      } else {
        onTabChange();
      }
    }
  }, [gradeState.list.length]);

  useEffect(() => {
    if (
      pageType === 'trial' &&
      trialUser &&
      trialUserState.subject &&
      trialUser.curriculum &&
      trialUser.grade
    ) {
      dispatch(
        getOnboardingSubjectSettingsData({
          subject: trialUserState.subject,
          curriculum: trialUser.curriculum,
          grade: trialUser.grade,
        })
      );

      onTabChange();
    }
  }, [trialUser]);

  useEffect(() => {
    if (state.name) {
      let _nameError = { ...defaultErrorData };

      if (state.name.length < 3) {
        _nameError = {
          visible: true,
          message: 'Name should have atleast 3 characters',
        };
      }

      setErrors((prevState) => ({
        ...prevState,
        name: _nameError,
      }));
    }
  }, [state.name]);

  useEffect(() => {
    if (state.email) {
      let _emailError = { ...defaultErrorData };

      if (!isEmailValid(state.email)) {
        _emailError = {
          visible: true,
          message: 'Invalid Email',
        };
      }

      setErrors((prevState) => ({
        ...prevState,
        email: _emailError,
      }));
    }
  }, [state.email]);

  useEffect(() => {
    if (state.password) {
      let _passwordError = { ...defaultErrorData };

      if (state.password.length < 8) {
        _passwordError = {
          visible: true,
          message: 'Password should have atleast 8 characters',
        };
      }

      setErrors((prevState) => ({
        ...prevState,
        password: _passwordError,
      }));
    }
  }, [state.password]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGradeChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) => {
    setState((prevState) => ({
      ...prevState,
      grade: value,
    }));
  };

  const handleGenderChange = (event: SelectChangeEvent) => {
    setState((prevState) => ({
      ...prevState,
      gender: event.target.value as TGender,
    }));
  };

  const updateTrialRequestUser = () => {
    if (trialUser) {
      dispatch(
        updateTrialUser({
          id: trialUser.id,
          curriculum: trialUserCurriculum,
          subject: trialUserSubject,
          grade: state.grade.value,
          updatedAt: new Date().getTime(),
        })
      );
    }
  };

  const createOnboardingUser = () => {
    if (signUpUser && signUpUser.id) {
      const API_DATA: ISignupUser = {
        id: signUpUser.id,
        name: state.name,
        email: state.email,
        password: state.password,
        grade: state.grade.value,
        country: state.country,
        gender: state.gender,
      };

      // URL TRACKING CHANGES
      const item: ISignupUser['urlTracking'] = {};

      if (state.urlTracking.utm_source)
        item.utm_source = state.urlTracking.utm_source;
      if (state.urlTracking.utm_medium)
        item.utm_medium = state.urlTracking.utm_medium;
      if (state.urlTracking.utm_campaign)
        item.utm_campaign = state.urlTracking.utm_campaign;
      if (state.urlTracking.utm_adgroup)
        item.utm_adgroup = state.urlTracking.utm_adgroup;
      if (state.urlTracking.utm_term)
        item.utm_term = state.urlTracking.utm_term;
      if (state.urlTracking.utm_content)
        item.utm_content = state.urlTracking.utm_content;
      if (state.urlTracking.lastUpdated) {
        item.lastUpdated = Number(state.urlTracking.lastUpdated);
      }

      if (state.salesAgent) {
        API_DATA.salesAgent = state.salesAgent;
      }

      if (Object.keys(item).length > 0) {
        API_DATA.urlTracking = item;
      }

      dispatch(signupUser(API_DATA));

      if (
        signUpUser &&
        signUpUser.subject &&
        signUpUser.curriculum &&
        state.grade.value
      ) {
        dispatch(
          getOnboardingSubjectSettingsData({
            subject: signUpUser.subject,
            curriculum: signUpUser.curriculum,
            grade: state.grade.value,
          })
        );
      }

      onTabChange();
    }
  };

  const showName = pageType === 'onboarding';
  const showEmail = pageType === 'onboarding';
  const showPassword = pageType === 'onboarding';
  const showGender = pageType === 'onboarding';
  const isGradeLoading = gradeState.status === 'loading';
  const isSignupLoading = signupState.status === 'loading' || isGradeLoading;
  const isUpdateLoading = trialUserState.status === 'loading' || isGradeLoading;
  const trialNextDisabled = state.grade.value === defaultSelectOption.value;
  const onboardingNextDisabled =
    state.name.trim().length === 0 ||
    state.email.length === 0 ||
    !isEmailValid(state.email) ||
    state.password.length < 8 ||
    state.grade.value === defaultSelectOption.value;

  const gradeOptions = [
    defaultSelectOption,
    ...gradeState.list.map((grade) => ({
      label: grade.grade,
      value: grade.grade,
    })),
  ];

  const renderNextButton = () => {
    switch (pageType) {
      case 'onboarding':
        return (
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            sx={{ maxWidth: '300px' }}
            onClick={() => createOnboardingUser()}
            disabled={onboardingNextDisabled}
            loading={isSignupLoading}
            className="onboarding-details-filled"
          >
            Next
          </LoadingButton>
        );

      case 'trial':
        return (
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            sx={{ maxWidth: '300px' }}
            onClick={() => updateTrialRequestUser()}
            disabled={trialNextDisabled}
            loading={isUpdateLoading}
          >
            Next
          </LoadingButton>
        );

      default:
        return null;
    }
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <Typography variant="h5" fontWeight={600}>
            Your Details
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color="secondary">
            Provide your Name, Email Address, Password & Grade
          </Typography>
        </div>
        <div style={{ padding: '0 30px' }}>
          <Grid container spacing={2}>
            {showName && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography fontWeight={500} sx={{ mb: '5px' }}>
                    Your Name *
                  </Typography>
                  <TextField
                    error={errors.name.visible}
                    fullWidth
                    size="small"
                    placeholder="John Smith"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    helperText={errors.name.message}
                  />
                </FormControl>
              </Grid>
            )}
            {showEmail && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography fontWeight={500} sx={{ mb: '5px' }}>
                    Email Address *
                  </Typography>
                  <TextField
                    error={errors.email.visible}
                    fullWidth
                    size="small"
                    name="email"
                    value={state.email}
                    placeholder="example@gmail.com"
                    onChange={handleInputChange}
                    helperText={errors.email.message}
                  />
                </FormControl>
              </Grid>
            )}
            {showPassword && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography fontWeight={500} sx={{ mb: '5px' }}>
                    Password *
                  </Typography>
                  <TextField
                    error={errors.password.visible}
                    fullWidth
                    size="small"
                    type="password"
                    name="password"
                    value={state.password}
                    placeholder="**********"
                    onChange={handleInputChange}
                    helperText={errors.password.message}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography fontWeight={500} sx={{ mb: '5px' }}>
                  Grade *
                </Typography>
                <Autocomplete
                  fullWidth
                  size="small"
                  disableClearable
                  options={gradeOptions}
                  onChange={handleGradeChange}
                  loading={isGradeLoading}
                  value={state.grade}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isGradeLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              params.InputProps.endAdornment
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {showGender && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography fontWeight={500} sx={{ mb: '5px' }}>
                    Gender
                  </Typography>
                </FormControl>
                <Select
                  fullWidth
                  size="small"
                  value={state.gender}
                  onChange={handleGenderChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <div style={{ minHeight: 'calc(66.5px + 15px)' }} />
      <Box sx={buttonStyles}>{renderNextButton()}</Box>
    </div>
  );
};

export default Step4;
