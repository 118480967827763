import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Grid, Box, BoxProps } from '@mui/material';

import { useAppSelector } from '../../../hooks';
import {
  getTrialUserState,
  updateSubject,
} from '../../../store/TrialUserSlice';
import { getSubjects, getSubjectState } from '../../../store/SubjectSlice';
import { ISubject } from '../../../store/CurriculumSlice';
import { getSignupState, signupUser } from '../../../store/SignupSlice';
import SubjectCard from '../components/SubjectCard';
import BasicLoader from '../../../components/Loaders/BasicLoader';
import SubjectsModal from '../modals/SubjectsModal';

interface IState {
  selectedSubject: ISubject | undefined;
  isSubjectModalOpen: boolean;
}

interface Step3Props {
  onTabChange: () => void;
  pageType: string;
}

const containerStyles: React.CSSProperties = {
  height: '100%',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const buttonStyles: BoxProps['sx'] = {
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 0',
  backgroundColor: 'background.default',
  position: 'absolute',
  bottom: '0',
  right: '0',
  width: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '40%',
  },
};

const Step3: React.FC<Step3Props> = ({ onTabChange, pageType }) => {
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set('step', 'subject');
  const newurl = `${window.location.protocol}//${window.location.host}${
    window.location.pathname
  }?${searchParams.toString()}`;
  window.history.pushState({ path: newurl }, '', newurl);

  // Store
  const subjectState = useAppSelector(getSubjectState);
  const signupState = useAppSelector(getSignupState);
  const trialUserState = useAppSelector(getTrialUserState);

  // State
  const [isRedirectReady, setRedirectReady] = useState(false);
  const [state, setState] = useState<IState>({
    selectedSubject: undefined,
    isSubjectModalOpen: false,
  });

  const signUpUser = signupState.user;
  const trialUser = trialUserState.user;
  const isSubjectLoading = subjectState.status === 'loading';
  const isSignupLoading = signupState.status === 'loading';

  let prioritizeList: ISubject[] = [];

  if (subjectState.list.length > 0) {
    prioritizeList = subjectState.list.slice(0, 7);
  }

  useEffect(() => {
    if (pageType === 'onboarding' && signUpUser && signUpUser.curriculum) {
      dispatch(
        getSubjects({
          curriculum: signUpUser.curriculum,
        })
      );
    } else if (pageType === 'trial' && trialUser && trialUser.curriculum) {
      dispatch(
        getSubjects({
          curriculum: trialUser.curriculum,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      subjectState.list &&
      subjectState.list.length &&
      pageType === 'onboarding' &&
      signUpUser &&
      signUpUser.subject
    ) {
      const subjectItem = subjectState.list.find(
        (item) => item.subject === signUpUser.subject
      );

      setState((prevState) => {
        return {
          ...prevState,
          selectedSubject: subjectItem || prevState.selectedSubject,
        };
      });
    }
  }, [subjectState.list]);

  useEffect(() => {
    if (
      subjectState.list &&
      subjectState.list.length &&
      pageType === 'trial' &&
      trialUserState &&
      trialUserState.subject
    ) {
      const subjectItem = subjectState.list.find(
        (item) => item.subject === trialUserState.subject
      );

      setState((prevState) => {
        return {
          ...prevState,
          selectedSubject: subjectItem || prevState.selectedSubject,
        };
      });
    }
  }, [subjectState.list]);

  useEffect(() => {
    if (signUpUser && signUpUser.subject && isRedirectReady) {
      onTabChange();
    }
  }, [signUpUser]);

  const onSubmit = () => {
    if (state.selectedSubject && state.selectedSubject.subject) {
      if (pageType === 'onboarding' && signUpUser && signUpUser.id) {
        dispatch(
          signupUser({
            id: signUpUser.id,
            subject: state.selectedSubject.subject,
          })
        );

        setRedirectReady(true);
      }

      if (pageType === 'trial' && trialUser && trialUser.curriculum) {
        dispatch(updateSubject(state.selectedSubject.subject));

        onTabChange();
      }
    }
  };

  return (
    <div style={containerStyles}>
      <div>
        <div style={{ textAlign: 'center', margin: '30px 0' }}>
          <Typography variant="h5" fontWeight={600}>
            Select Subject
          </Typography>
          <Typography variant="subtitle2" fontWeight={500} color="secondary">
            Please select a subject of your choice to begin
            <br />
            curating your experience
          </Typography>
        </div>

        {isSubjectLoading ? (
          <BasicLoader />
        ) : (
          <div>
            <div style={{ padding: '0 30px' }}>
              <Grid container spacing={2}>
                {prioritizeList.map((subject, index) => {
                  const _isSelected =
                    state.selectedSubject?.subject === subject.subject;

                  return (
                    <Grid key={index} item xs={3} sm={3} md={2} lg={3}>
                      <SubjectCard
                        selected={_isSelected}
                        subject={subject.subject}
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            selectedSubject: subject,
                          }))
                        }
                      />
                    </Grid>
                  );
                })}

                {subjectState.list.length > 7 && (
                  <Grid item xs={3} sm={3} md={2} lg={3}>
                    <SubjectCard
                      disabled={subjectState.list.length <= 7}
                      selected={
                        Boolean(state.selectedSubject) &&
                        !prioritizeList.some((s) => s === state.selectedSubject)
                      }
                      subject={`${
                        state.selectedSubject &&
                        subjectState.list.indexOf(state.selectedSubject) >= 7
                          ? `${state.selectedSubject.subject} (Other)`
                          : 'Other'
                      }`}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          isSubjectModalOpen: true,
                        }))
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        )}
      </div>
      <div style={{ minHeight: 'calc(66.5px + 15px)' }} />
      <div>
        <Box sx={buttonStyles}>
          <LoadingButton
            fullWidth
            variant="contained"
            color="primary"
            sx={{ maxWidth: '300px' }}
            disabled={!state.selectedSubject}
            onClick={onSubmit}
            loading={isSignupLoading}
            className="onboarding-level-next-button"
          >
            Next
          </LoadingButton>
        </Box>
      </div>
      {/* MODALS */}
      <SubjectsModal
        open={state.isSubjectModalOpen}
        selectedSubject={state.selectedSubject}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isSubjectModalOpen: false,
          }))
        }
        onSubjectChange={(curriculum) => {
          setState((prevState) => ({
            ...prevState,
            selectedSubject: curriculum,
            isSubjectModalOpen: false,
          }));
        }}
      />
    </div>
  );
};

export default Step3;
