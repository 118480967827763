import { createAsyncThunk } from '@reduxjs/toolkit';
import APIDataProvider from '../../dataProvider/APIDataProvider';
import { ApiPaths } from '../../dataProvider/APIPaths';
import { ISubject } from '../CurriculumSlice';

export interface IGetSubjects {
  curriculum: string;
}

const SubjectApiDataProvider = new APIDataProvider<ISubject[]>();

export const getSubjects = createAsyncThunk(
  'subject/list',
  async (data: IGetSubjects) => {
    const response = await SubjectApiDataProvider.create(
      ApiPaths.subject,
      data
    );

    return response.data;
  }
);

export default getSubjects;
