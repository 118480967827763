import React from 'react';
import {
  Box,
  BoxProps,
  Stack,
  Grid,
  StackProps,
  Typography,
} from '@mui/material';
import Lottie from 'lottie-react';
// HELPERS
import { isMobile, SafeAreaGaps } from '../../helper';
// HOOKS
import { useAppSelector } from '../../hooks';
// STORE
import { getSignupState } from '../../store/SignupSlice';
// ANIMATION
import classCreationAnimation from './animations/classCreationAnimation.json';

// STYLES
const contentContainerHeight = isMobile ? '100vh' : 'calc(100vh - 184.97px)';

const containerStyles: BoxProps['sx'] = {
  minHeight: '100vh',
  overflowY: 'auto',
  background: {
    xs: 'none',
    sm: 'none',
    md: 'none',
    lg: `url('/images/backgrounds/bg-lg.png') center/101% 100%`,
  },
  p: {
    xs: '15px',
    lg: 'unset',
  },
};

const subContentContainerStyles: BoxProps['sx'] = {
  display: 'flex',
  p: { xs: '15px', lg: '15px 100px' },
  justifyContent: 'center',
  mb: '30px',
};

const mobileImageContainerStyles: StackProps['sx'] = {
  backgroundColor: 'background.default',
  position: 'fixed',
  top: SafeAreaGaps.top,
  width: '100%',
  left: 0,
  p: '15px 0',
  display: { xs: 'flex', lg: 'none' },
  zIndex: 1,
};

const contentContainerStyles: BoxProps['sx'] = {
  backgroundColor: 'background.default',
  minHeight: contentContainerHeight,
};

const TrialClassCreationLoader: React.FC = () => {
  // HOOKS
  const signupState = useAppSelector(getSignupState);

  // RENDER
  return (
    <Box sx={containerStyles}>
      <Stack
        direction="row"
        justifyContent="center"
        sx={mobileImageContainerStyles}
      >
        <img
          src="/images/logos/tp-logo-green.png"
          alt="tp-logo"
          width="120px"
        />
      </Stack>
      <Box sx={{ minHeight: '85px', display: { xs: 'block', lg: 'none' } }} />
      {!isMobile && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <img
            src="/images/logos/tp-logo-text-white.png"
            alt="tp-logo"
            style={{ width: '150px', marginTop: '20px' }}
          />
          <img
            src="/images/shapes/wave.png"
            alt="wave-shape"
            style={{ width: '100%', marginTop: '20px', marginBottom: '-2px' }}
          />
        </Stack>
      )}
      <Box sx={contentContainerStyles}>
        <Box sx={subContentContainerStyles}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            display="flex"
            direction="row"
            // sx={{ maxWidth: '600px' }}
          >
            <Grid display="flex" justifyContent="center" item xs={12}>
              <Typography
                fontSize={24}
                variant="h2"
                color="primary"
                justifyContent="center"
                fontWeight={500}
              >
                {signupState.user && signupState.user.name
                  ? signupState.user.name
                  : ''}
                we are crafting your learning experince
              </Typography>
            </Grid>
            <Grid
              display="flex"
              justifyContent="center"
              item
              xs={isMobile ? 10 : 6}
            >
              <Lottie animationData={classCreationAnimation} loop />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TrialClassCreationLoader;
