import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { TP_COLORS } from '../../../constants';
import { getTrialState } from '../../../store/TrialSlice';
import { getTrialUserState } from '../../../store/TrialUserSlice';
import { useAppSelector } from '../../../hooks';
import { isMobile } from '../../../helper';
import ImageContents from '../components/ImageContents';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import TrialClassCreationLoader from '../../../components/Loaders/TrialClassCreationLoader';

const imageContainerStyles: BoxProps['sx'] = {
  height: '100vh',
  width: {
    xs: '100%',
    lg: '60%',
  },
  background: `url('/images/backgrounds/bg-lg.png') center/contain`,
  display: {
    xs: 'none',
    lg: 'flex',
  },
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const stepContainer: BoxProps['sx'] = {
  height: '100vh',
  width: {
    xs: '100%',
    lg: '40%',
  },
};

interface StepsProps {
  pageType: string;
}

const OnboardingSteps: React.FC<StepsProps> = ({ pageType }) => {
  // Store
  const trialState = useAppSelector(getTrialState);
  const trialUserState = useAppSelector(getTrialUserState);

  // State
  const [activeStep, setActiveStep] = useState(0);

  const trialUser = trialUserState.user;

  let steps: number[] = [1, 2, 3, 4, 5];
  let isBackButtonVisible = activeStep > 0 && activeStep < 4;
  let _containerHeight = 'calc(100vh - 24px - 48.41px - 45px)';

  if (pageType === 'trial') {
    steps = [1, 2, 3, 4];
    isBackButtonVisible = activeStep > 0 && activeStep < 4;

    if (pageType === 'trial') {
      isBackButtonVisible = false;

      if (trialUser) {
        if (activeStep === 1 && trialUser.curriculum) {
          isBackButtonVisible = false;
        }
      }
    }
  }

  if (isBackButtonVisible) {
    _containerHeight = 'calc(100vh - 24px - 48.41px - 45px - 70px)';
  }

  const stepContentContainer: React.CSSProperties = {
    height: _containerHeight,
    maxHeight: _containerHeight,
    overflowY: 'auto',
  };

  const onGoBack = () => {
    if (pageType === 'trial' && activeStep === 3) {
      setActiveStep(1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const renderSteps = () => {
    if (pageType === 'trial') {
      if (activeStep === 0) {
        return <Step2 onTabChange={() => setActiveStep(1)} pageType="trial" />;
      }

      if (activeStep === 1) {
        return <Step3 onTabChange={() => setActiveStep(2)} pageType="trial" />;
      }

      if (activeStep === 2) {
        return (
          <Step4
            onTabChange={() => {
              setActiveStep(3);
            }}
            pageType="trial"
          />
        );
      }

      if (activeStep === 3) {
        return <Step5 onTabChange={() => setActiveStep(0)} pageType="trial" />;
      }

      return null;
    }

    if (activeStep === 0) {
      return <Step1 onTabChange={(step) => setActiveStep(step || 1)} />;
    }

    if (activeStep === 1) {
      return (
        <Step2 onTabChange={() => setActiveStep(2)} pageType="onboarding" />
      );
    }

    if (activeStep === 2) {
      return (
        <Step3 onTabChange={() => setActiveStep(3)} pageType="onboarding" />
      );
    }

    if (activeStep === 3) {
      return (
        <Step4 onTabChange={() => setActiveStep(4)} pageType="onboarding" />
      );
    }

    if (activeStep === 4) {
      return (
        <Step5 onTabChange={() => setActiveStep(3)} pageType="onboarding" />
      );
    }

    return null;
  };

  if (trialState.isTrialClassCreatingOrLoading) {
    return <TrialClassCreationLoader />;
  }

  return (
    <div>
      <Box display="flex">
        {!isMobile && (
          <Box sx={imageContainerStyles}>
            <ImageContents />
          </Box>
        )}

        <Box sx={stepContainer}>
          <div>
            {/* LOGO */}
            <Stack direction="row" justifyContent="center" sx={{ m: '15px 0' }}>
              <img
                src="/images/logos/tp-logo-text-green.png"
                alt="tp-logo"
                style={{ width: '120px' }}
              />
            </Stack>

            {/* STEPPER */}
            <div style={{ padding: '0 15px 15px 15px' }}>
              <Stepper activeStep={activeStep}>
                {steps.map((step, index) => (
                  <Step
                    key={`step-${index}`}
                    active={activeStep === index}
                    completed={activeStep >= step}
                  >
                    <StepLabel />
                  </Step>
                ))}
              </Stepper>
            </div>
            {isBackButtonVisible ? (
              <div style={{ padding: '0 30px' }}>
                <IconButton
                  color="inherit"
                  style={{ backgroundColor: TP_COLORS.paperGray.light }}
                  onClick={onGoBack}
                >
                  <ArrowBackIcon />
                </IconButton>
              </div>
            ) : null}

            <div style={stepContentContainer}>{renderSteps()}</div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default OnboardingSteps;
