import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAnalytics } from '.';
import { ApiPaths } from '../../dataProvider/APIPaths';
import APIDataProvider from '../../dataProvider/APIDataProvider';

interface ICreateAnaytics {
  userId?: string;
  type: string;
  data: Record<string, unknown>;
}

const AnalyticsApiDataProvider = new APIDataProvider<IAnalytics>();

export const createAnalytics = createAsyncThunk(
  'analytics/create',
  async (data: ICreateAnaytics) => {
    const response = await AnalyticsApiDataProvider.create(
      ApiPaths.analytics,
      data
    );
    return response.data;
  }
);

export default createAnalytics;
