import { createSlice } from '@reduxjs/toolkit';
// STORE
import { RootState } from '..';
// API
import { getFindUsOptions } from './FindUsApi';

export interface IFindUsOption {
  key: string;
  title: string;
}
export interface IFindUsState {
  status: 'idle' | 'loading' | 'rejected';
  list: Array<IFindUsOption>;
}

const initialState: IFindUsState = {
  status: 'idle',
  list: [],
};

export const FindUsSlice = createSlice({
  name: 'findUs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder
      .addCase(getFindUsOptions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFindUsOptions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.list = action.payload;
      })
      .addCase(getFindUsOptions.rejected, (state) => {
        state.status = 'rejected';
      });
  },
});

export const getFindUsState = (state: RootState): RootState['findUs'] =>
  state.findUs;

export * from './FindUsApi';
export default FindUsSlice.reducer;
