export interface ISubjectColors {
  light: React.CSSProperties['color'];
  main: React.CSSProperties['color'];
}

export const SubjectColors: ISubjectColors[] = [
  { light: '#BFDAE0', main: '#01A9CC' },
  { light: '#f7f3e1', main: '#FFC300' },
  { light: '#C6DECD', main: '#2CBE58' },
  { light: '#E5C6C7', main: '#E82D35' },
  { light: '#E1C8DB', main: '#D437B0' },
  { light: '#BFDDDB', main: '#01B8AF' },
  { light: '#E8CEBF', main: '#FF5E00' },
  { light: '#C2BFDD', main: '#1801B8' },
];

export const TP_COLORS = {
  black: '#000000',
  white: '#fff',
  listItemGray: {
    light: '#F8F9FD',
    dark: '#36393e',
  },
  paperGray: {
    light: '#F9F9F9',
    dark: '#36393e',
  },
};

export default TP_COLORS;
