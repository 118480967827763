import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
// HOOKS
import { useAppSelector } from '../../../hooks';
// STORE
import {
  getTrialState,
  updateTrialTopicAndSpecialRequirements,
} from '../../../store/TrialSlice';
// CONSTANTS
import { TP_COLORS } from '../../../constants';

interface AddTopicAndSpecialRequirementsModalProps {
  open: boolean;
  onClose: () => void;
}

const AddTopicAndSpecialRequirementsModal: React.FC<AddTopicAndSpecialRequirementsModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch();

  // STORE
  const trialState = useAppSelector(getTrialState);

  // STATE
  const [state, setState] = useState({
    topic: '',
    specialRequirements: '',
  });

  const topicFieldEnabled = useFlag(
    'onboarding_topic_request_topic_field_enabled'
  );

  // USE EFFECT
  useEffect(() => {
    setState((prevState) => {
      const newState = { ...prevState };

      if (trialState.trialClass) {
        if (trialState.trialClass.topic) {
          newState.topic = trialState.trialClass.topic;
        }
        if (trialState.trialClass.specialRequirements) {
          newState.specialRequirements =
            trialState.trialClass.specialRequirements;
        }
      }

      return newState;
    });
  }, []);

  // METHODS
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { topic, specialRequirements } = state;

    if (trialState.trialClass) {
      dispatch(
        updateTrialTopicAndSpecialRequirements({
          userId: trialState.trialClass.studentId,
          id: trialState.trialClass.id,
          topic: topic.trim(),
          specialRequirements: specialRequirements.trim(),
        })
      );
    }

    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        {topicFieldEnabled
          ? 'Add Topic & Special Requirements'
          : 'Add Special Requirements'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {topicFieldEnabled ? (
            <Grid item xs={12}>
              <div style={{ marginBottom: '8px' }}>
                <Typography fontWeight={600}>Topic</Typography>
              </div>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  name="topic"
                  value={state.topic}
                  placeholder="Enter here..."
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <div style={{ marginBottom: '8px' }}>
              <Typography fontWeight={600}>Special Requirements</Typography>
            </div>
            <FormControl fullWidth>
              <TextField
                multiline
                size="small"
                minRows={3}
                maxRows={6}
                name="specialRequirements"
                value={state.specialRequirements}
                placeholder="Enter here..."
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '15px 30px' }}>
        <Button
          size="small"
          variant="contained"
          color="inherit"
          onClick={onClose}
          style={{ color: TP_COLORS.black, marginRight: '10px' }}
        >
          Dismiss
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTopicAndSpecialRequirementsModal;
