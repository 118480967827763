import Axios, { AxiosInstance } from 'axios';
import { getAuthTokenFromLocalStorage } from '../helper';

export default (): AxiosInstance =>
  Axios.create({
    baseURL: `${process.env.REACT_APP_TUTOPIYA_ONBOARDING_BE_BASE_URL}`,
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getAuthTokenFromLocalStorage()}`,
    },
  });
