import { createAsyncThunk } from '@reduxjs/toolkit';

import APIDataProvider from '../../dataProvider/APIDataProvider';
import ApiPaths from '../../dataProvider/APIPaths';
import { IUser } from '../SignupSlice';

const UserApiDataProvider = new APIDataProvider<IUser>();

export const getTrialUserById = createAsyncThunk(
  'trialUser/get',
  async (userId: string) => {
    const response = await UserApiDataProvider.getById(
      `${ApiPaths.trialUser}?id=${userId}`
    );

    return response.data;
  }
);

export const updateTrialUser = createAsyncThunk(
  'trialUser/update',
  async (updateData: {
    id: string;
    curriculum?: string;
    subject?: string;
    grade?: string;
    updatedAt?: number;
    country?: string;
  }) => {
    const response = await UserApiDataProvider.updateById(
      ApiPaths.updateTrialUser,
      updateData
    );

    return response.data;
  }
);
